import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartVisible = new BehaviorSubject<boolean>(false);
  isCartVisible = this.cartVisible.asObservable();

  private items = new BehaviorSubject<any[]>(this.getItemsFromLocalStorage());
  cartItems = this.items.asObservable();

  private total = new BehaviorSubject<number>(
    this.calculateTotal(this.getItemsFromLocalStorage())
  );
  cartTotal = this.total.asObservable();

  showCart() {
    this.cartVisible.next(true);
  }

  hideCart() {
    this.cartVisible.next(false);
  }

  addItem(item: any) {
    const currentItems = this.items.value;
    currentItems.push(item);
    this.items.next(currentItems);
    this.updateLocalStorage(currentItems);
    const currentTotal = this.total.value;
    this.total.next(currentTotal + item.price);
  }

  removeFromCart(item: any) {
    const currentItems = this.items.value;
    const itemIndex = currentItems.findIndex(
      (cartItem) => cartItem.id === item.id
    );
    if (itemIndex > -1) {
      currentItems.splice(itemIndex, 1);
      this.items.next(currentItems);
      this.updateLocalStorage(currentItems);

      const currentTotal = this.total.value;
      this.total.next(currentTotal - item.price);
    }
  }

  private updateLocalStorage(items: any[]) {
    localStorage.setItem('cartItems', JSON.stringify(items));
  }

  private getItemsFromLocalStorage(): any[] {
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  }

  private calculateTotal(items: any[]): number {
    return items.reduce((total, item) => total + item.price, 0);
  }

  isItemInCart(item: any): boolean {
    return this.items.value.some((cartItem) => cartItem.id === item.id);
  }
}
