<div class="container">
    <div class="upload-section">
        <p>Hình ảnh và Video sản phẩm</p>
        <a href="#">Xem thêm về Quy định đăng tin của SecondLife</a>
        <div class="selected-files">
            <p>Các tập tin đã chọn:</p>
            <ul>
                <li *ngFor="let file of selectedFiles" style="margin: 10px;">
                    {{file.name}} <button mat-raised-button color="primary" (click)="removeFile(file)">Xóa</button>
                </li>
            </ul>
        </div>
        <div class="upload-box">
            <input type="file" id="file" (change)="onFileSelected($event)" multiple />
            <label for="file">+</label>
        </div>
    </div>
    <div class="category-section" >
        <div class="form-container">
            <div *ngIf="loading" class="loading-container">
                <mat-spinner></mat-spinner>
            </div>
            <form [formGroup]="productForm" (ngSubmit)="onSubmit()" *ngIf="!loading">
                <div class="form-fields"> 
                    <mat-form-field style="width: 250px;" appearance="fill">
                        <mat-label>Danh Mục Tin Đăng</mat-label>
                        <mat-select [(value)]="selectedCategory" (selectionChange)="onCategoryChange($event)" required>
                            <mat-option *ngFor="let category of categories" [value]="category">
                                {{category}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width: 170px;"  appearance="fill">
                        <mat-label>Danh Mục Con</mat-label>
                        <mat-select [(value)]="selectedSubcategory"
                            (selectionChange)="onSubcategoryChange($event)" required>
                            <mat-option *ngFor="let subcategory of subcategories" [value]="subcategory">
                                {{subcategory}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="shouldShowUploadForm()">
                    <p style="font-weight: 700;font-size: 20px;">Thông tin chi tiết</p>
                    <ng-container *ngFor="let field of dynamicFields">
                        <mat-form-field class="form-field-input">
                            <mat-label>{{ field.label }}</mat-label>
                            <div *ngIf="field.type === 'select';">
                                <mat-select [placeholder]="field.placeholder" [formControlName]="field.name">
                                    <mat-option *ngFor="let option of field.options" [value]="option">
                                        {{ option }}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div *ngIf="field.type === 'input'">
                                <input matInput [placeholder]="field.placeholder" [formControlName]="field.name">
                            </div>
                            <div *ngIf="field.type === 'textarea'">
                                <textarea matInput [placeholder]="field.placeholder" [formControlName]="field.name"></textarea>
                            </div>
                            <small class="text-danger mx-3 text-end d-block" 
                                *ngIf="productForm.get(field.name)?.hasError('maxlength')">
                                Tiêu đề không được vượt quá 50 ký tự.
                            </small>
                            <small class="text-danger mx-3 text-end d-block" 
                                *ngIf="productForm.get(field.name)?.hasError('pattern')">
                                Giá bán phải là chữ số
                            </small>
                            <small class="text-danger mx-3 text-end d-block" 
                                *ngIf="productForm.get(field.name)?.hasError('min') || productForm.get(field.name)?.hasError('max')">
                                Số lượng phải > 0 và < 100 
                            </small>
                            <small class="text-danger mx-3 text-end d-block"
                                *ngIf="productForm.get(field.name)?.hasError('required') && productForm.get(field.name)?.touched">
                                Trường này là bắt buộc
                            </small>
                        </mat-form-field>
                    </ng-container>
                    <div>
                        <button (ngSubmit)="onSubmit()"  class="btn btn-primary mt-3" type="submit" [disabled]="productForm.invalid">Đăng Tin</button>
                    </div>
                </div>
            </form>
        </div>
        <img src="assets/images/demo/empty-category.svg" alt="" class="payment-icon" *ngIf="!shouldShowUploadForm()">
    </div>
</div>