import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PasswordComponent } from './password/password.component';
import { ProfileComponent } from './profile/profile.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogElementsComponent } from './dialog-elements/dialog-elements.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { MatRadioModule } from '@angular/material/radio';
import { UpgradeAccountComponent } from './upgrade-account/upgrade-account.component';
@NgModule({
  declarations: [
    PasswordComponent,
    UserProfileComponent,
    ProfileComponent,
    DialogElementsComponent,
    ImageCropperComponent,
    UpgradeAccountComponent,
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatSliderModule,
    CdkDrag,
    MatButtonModule,
    MatDialogModule,
  ],
  exports: [UserProfileComponent],
})
export class UserProfileModule {}
