import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Cropper from 'cropperjs';
import { UserProfile } from 'src/app/models/user.profile';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements AfterViewInit {
  sanitizedUrl!: SafeUrl;
  cropper!: Cropper;

  @ViewChild('imageElement') imageElement!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public image: string,
    private sanitizer: DomSanitizer,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.image);
  }

  ngAfterViewInit(): void {
    const image = this.imageElement.nativeElement;
    this.cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 1,
    });
  }

  getRoundedCanvas(sourceCanvas: HTMLCanvasElement): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
    return canvas;
  }

  crop(): void {
    if (this.cropper) {
      const croppedCanvas = this.cropper.getCroppedCanvas();
      const roundedCanvas = this.getRoundedCanvas(croppedCanvas);
      this.dialogRef.close(roundedCanvas.toDataURL());
      const dataUrl = roundedCanvas.toDataURL();
      this.accountService.changeAvatar(dataUrl);
    } else {
      console.error('Cropper is not initialized.');
      this.dialogRef.close(null);
    }
  }

  reset(): void {
    if (this.cropper) {
      this.cropper.reset();
    }
  }
}
