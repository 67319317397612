import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { Utilities } from './utilities';

interface UserConfiguration {
  language: string | null;
  homeUrl: string | null;
  themeId: number | null;
  showDashboardStatistics: boolean | null;
  showDashboardNotifications: boolean | null;
  showDashboardTodo: boolean | null;
  showDashboardBanner: boolean | null;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor() {}
  // ***Specify default configurations here***
  public static readonly defaultLanguage = 'en';
  public static readonly defaultHomeUrl = '/';
  public static readonly defaultThemeId = 1;
  public static readonly defaultShowDashboardStatistics = true;
  public static readonly defaultShowDashboardNotifications = true;
  public static readonly defaultShowDashboardTodo = false;
  public static readonly defaultShowDashboardBanner = true;

  public baseUrl = environment.baseUrl ?? Utilities.baseUrl();
  public loginEndpoint = 'account/loginwithopenidconnect';
  public logoutEndpoint = 'account/logout';
}
