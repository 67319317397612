import { Component, Input } from '@angular/core';
import { Comment } from 'src/app/models/comment/comment';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.css'],
})
export class CommentSectionComponent {
  @Input() comments: Comment[] = [];

  likeComment(comment: Comment) {
    comment.likes += 1;
  }

  dislikeComment(comment: Comment) {
    comment.dislikes += 1;
  }

  replyToComment(comment: Comment) {
    console.log(`Reply to ${comment.name}`);
  }
}
