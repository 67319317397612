export class MessageSend {
  public message: string;

  constructor(message: string) {
    this.message = message;
  }
}
export class Message {
  public senderName: string;
  public senderId: string;
  public receiverName: string;
  public avatalSender: string;
  public message: string;
  public timestamp: string;
  constructor(
    senderName: string,
    senderId: string,
    receiverName: string,
    avatalSender: string,
    message: string,
    timestamp: string
  ) {
    this.senderName = senderName;
    this.senderId = senderId;
    this.receiverName = receiverName;
    this.avatalSender = avatalSender;
    this.message = message;
    this.timestamp = timestamp;
  }
}
