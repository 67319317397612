<div
  class="navbar flex items-center justify-between bg-sky-700 pl-4 border-b h-[70px] border-gray-300 fixed w-full top-0 z-50"
>
  <div class="nav-links flex items-center gap-4">
    <a
      class="nav-link font-bold text-[18px] text-gray-900 hover:text-white lg:pl-5"
      (click)="openModal()"
    >
      <svg
        class="text-gray-800 dark:text-white w-10 h-10 cursor-pointer"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-width="2"
          d="M5 7h14M5 12h14M5 17h14"
        />
      </svg>
    </a>
    <a
      class="nav-link font-bold text-[18px] text-gray-900 hover:text-white hidden lg:inline pl-5"
      routerLink=""
      >Home</a
    >
    <a
      class="nav-link font-bold text-[18px] text-gray-900 hover:text-white hidden lg:inline"
      routerLink="/chat"
      >Chat</a
    >
  </div>
  <div
    class="flex-grow max-md:flex justify-center items-center relative md:max-w-xl"
  >
    <div
      class="flex items-center border border-gray-300 rounded-full bg-gray-100 px-3 py-2 relative z-10"
    >
      <input
        type="text"
        placeholder="Search"
        aria-label="Search"
        class="flex-grow border-none outline-none py-1 rounded-full text-sm bg-transparent"
        [formControl]="searchControl"
        (focus)="showDropdown = true"
        (blur)="hideDropdown()"
        (input)="onSearchChange()"
      />
      <button
        aria-label="Search"
        class="search-button bg-none border-none cursor-pointer"
      >
        <img
          class="search-icon w-5 h-5"
          src="assets/icons/search.svg"
          alt="Search"
        />
      </button>
    </div>
    <div
      *ngIf="showDropdown"
      class="absolute top-full bg-white shadow-lg z-20 w-full p-3 rounded-lg max-h-72 overflow-y-auto mt-[10px]"
    >
      <div class="section-title font-bold mt-2">Recent searches</div>
      <div
        *ngFor="let item of recentSearches"
        class="recent-item bg-gray-200 cursor-pointer p-2 rounded-full text-sm inline-block m-1"
      >
        {{ item }}
      </div>
      <a
        (click)="clearHistory()"
        class="clear-history text-blue-600 cursor-pointer text-sm float-right"
        >Clear search history</a
      >
      <div class="section-title font-bold mt-2">Popular searches</div>
      <div
        *ngFor="let option of popularSearches"
        class="search-option bg-gray-200 cursor-pointer p-2 rounded-full text-sm inline-block m-1"
      >
        {{ option }}
      </div>
    </div>
  </div>
  <div class="right-icons flex items-center gap-4 pr-5">
    <img
      routerLink="/mypost/listing"
      class="large-icon w-9 h-9 mr-2 cursor-pointer max-sm:hidden"
      src="assets/icons/menu-button.svg"
      alt="Menu"
    />
    <div
      *ngIf="userProfile.isAuthenticated; else noAuth"
      class="hidden lg:inline"
    >
      <img
        class="large-icon w-9 h-9 mr-2 cursor-pointer"
        [matMenuTriggerFor]="menu"
        src="assets/icons/person-lines-fill.svg"
        alt="User"
      />
      <mat-menu #menu="matMenu" class="mt-3">
        <button mat-menu-item routerLink="/account/user">
          Account Information
        </button>
        <button mat-menu-item routerLink="/upload-product">Post new</button>
        <button mat-menu-item routerLink="/user/upgrade-account">
          Upgrade Account
        </button>
      </mat-menu>
    </div>
    <a
      *ngIf="userProfile.isAuthenticated"
      (click)="clearLocalStorage()"
      class="nav-link font-bold text-gray-900 hover:text-white text-[18px] max-sm:hidden"
      [href]="logoutEndpoint"
      >Log out</a
    >
  </div>
</div>

<ng-template #noAuth>
  <a
    class="nav-link font-bold text-gray-900 hover:text-white text-[18px] max-sm:hidden"
    [href]="loginEndpoint"
    >Log in</a
  >
</ng-template>

<div
  *ngIf="isModalOpen"
  class="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-start"
  (click)="closeModal()"
>
  <div
    class="bg-yellow-100 px-2 py-5 max-md:w-[60%] max-lg:w-[40%] h-full relative slide-in-left"
    (click)="$event.stopPropagation()"
  >
    <button
      class="absolute top-3 right-3 text-3xl font-bold"
      (click)="closeModal()"
    >
      X
    </button>
    <div class="flex flex-col space-y-4 px-2">
      <div
        class="flex items-center cursor-pointer space-x-3"
        routerLink="/"
        (click)="closeModal()"
      >
        <svg
          class="text-gray-800 w-10"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
            clip-rule="evenodd"
          />
        </svg>
        <h2>Home</h2>
      </div>
      <div
        class="flex items-center cursor-pointer space-x-3"
        routerLink="/chat"
        (click)="closeModal()"
      >
        <svg
          class="w-10 text-gray-800"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z"
          />
        </svg>

        <h2>Message...</h2>
      </div>
      <div
        class="flex items-center cursor-pointer space-x-3"
        routerLink="/"
        (click)="loginWithPasswordFlow()"
      >
        <svg
          class="text-gray-800 w-10"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M20 12H8m12 0-4 4m4-4-4-4M9 4H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h2"
          />
        </svg>
        <h2>Password_Flow<br />(DevUI_Admin)</h2>
      </div>
      <div
        *ngIf="userProfile.isAuthenticated"
        class="flex items-center cursor-pointer space-x-3"
        (click)="clearLocalStorage()"
      >
        <svg
          class="text-gray-800 w-10"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 12h14M5 12l4-4m-4 4 4 4"
          />
        </svg>
        <h2>Log out</h2>
      </div>
    </div>
  </div>
</div>
