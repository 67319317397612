import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Payment } from 'src/app/models/payment/payment';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-checkout-subscription',
  templateUrl: './checkout-subscription.component.html',
  styleUrls: ['./checkout-subscription.component.css'],
})
export class CheckoutSubscriptionComponent implements OnInit {
  selectedPackage: any;
  selectedPaymentMethod: string = 'MOMO';
  loading = false; // Loading state
  paymentMethods = [
    {
      id: 'MOMO',
      name: 'MOMO',
      description: 'Thanh toán qua ví MOMO',
      image: 'assets/images/momo.png',
      dev: true,
    },
    {
      id: 'ZALOPAY',
      name: 'ZALOPAY',
      description: 'Thanh toán qua ví ZALOPAY',
      image: 'assets/images/zalopay.png',
      dev: false,
    },
    {
      id: 'VNPAY',
      name: 'VNPAY',
      description: 'Thanh toán qua ví VNPAY',
      image: 'assets/images/vnpay.png',
      dev: true,
    },
    {
      id: 'VISA',
      name: 'VISA',
      description: 'Thanh toán qua thẻ VISA',
      image: 'assets/images/visa.png',
      dev: false,
    },
    {
      id: 'PAYPAL',
      name: 'PayPal',
      description: 'Thanh toán qua PayPal',
      image: 'assets/images/paypal.png',
      dev: false,
    },
  ];

  constructor(private router: Router, private paymentService: PaymentService) {}
  ngOnInit(): void {
    if (history.state && history.state.selectedPackage) {
      this.selectedPackage = history.state.selectedPackage;
    }
  }
  onSubmit() {
    if (this.selectedPackage) {
      const payment = new Payment(
        'Nội dung thanh toán cho gói ' + this.selectedPackage.subtitle,
        'VND',
        'REF123456',
        parseInt(this.selectedPackage.discountedPrice.replace('.', '')),
        'vi',
        1001,
        this.selectedPaymentMethod
      );
      this.paymentService.processPayment(payment).subscribe((response) => {
        this.loading = true;
        if (response && response.data && response.data.paymentUrl) {
          window.location.href = response.data.paymentUrl;
        } else {
          alert('Payment URL not found. Please try again.');
        }
      });
    } else {
      alert('Package not found. Please try again.');
    }
  }
  isPaymentMethodDev(id: string): boolean {
    const method = this.paymentMethods.find((method) => method.id === id);
    return method ? method.dev : false;
  }
  isPaymentButtonDisabled(): boolean {
    if (!this.selectedPaymentMethod) {
      return true;
    }
    const isDev = this.isPaymentMethodDev(this.selectedPaymentMethod);
    return !isDev;
  }
}
