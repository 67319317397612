import { Component, OnInit } from '@angular/core';
import { UserProfile } from 'src/app/models/user.profile';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../account.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LocationService } from 'src/app/services/location.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup = new FormGroup({});
  userProfile: UserProfile = new UserProfile();
  constructor(
    private formBuider: FormBuilder,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private locationService: LocationService
  ) {}
  ngOnInit(): void {
    this.profileForm = this.formBuider.group({
      fullName: ['', Validators.required],
      email: [''],
      gender: [''],
      address: [''],
      phoneNumber: [''],
      dateOfBirth: [''],
    });
    this.getUserProfile();
  }

  onSubmit() {
    if (this.profileForm.valid) {
      let userProfile: UserProfile = this.profileForm.value;
      if (userProfile) {
        this.accountService.updateUserProfile(userProfile).subscribe((next) => {
          this.openSnackBar('Profile updated successfully!');
          let user1 = next;
          console.log(user1);
        });
      }
    }
  }
  openSnackBar(message: string, action: string = 'Close') {
    const config = new MatSnackBarConfig();
    config.duration = 2000;
    config.horizontalPosition = 'right';
    config.verticalPosition = 'top';
    this.snackBar.open(message, action, config);
  }
  getUserProfile() {
    this.accountService.getUserProfile().subscribe((data) => {
      this.userProfile = data;
      this.profileForm.patchValue(this.userProfile);

      if (!this.userProfile.address || this.userProfile.address.trim() === '') {
        this.locationService
          .getUserLocation()
          .then((position) => {
            const { latitude, longitude } = position.coords;
            return this.locationService.getAddressFromCoordinates(
              latitude,
              longitude
            );
          })
          .then((addressData) => {
            console.log('User Address:', addressData.display_name);
            this.profileForm.patchValue({
              address: addressData.display_name,
            });
          })
          .catch((error) => {
            this.requestLocationPermission();
          });
      }
    });
  }
  private requestLocationPermission() {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'prompt') {
        this.getUserProfile();
      }
    });
  }
}
