<h1>User Profile</h1>

<form class="profile-form" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
  <mat-form-field class="profile-full-width">
    <mat-label>Họ tên</mat-label>
    <input
      matInput
      placeholder="FullName"
      value="{{ userProfile.fullName }}"
      formControlName="fullName"
    />
    <small
      class="text-danger mx-3 text-end d-block"
      *ngIf="
        profileForm.get('fullName')?.invalid &&
        profileForm.get('fullName')?.touched
      "
    >
      Trường này là bắt buộc
    </small>
  </mat-form-field>
  <mat-form-field class="profile-full-width">
    <mat-label>Email</mat-label>
    <input
      matInput
      placeholder="Email"
      value="{{ userProfile.email }}"
      formControlName="email"
    />
  </mat-form-field>

  <div class="form-container">
    <mat-form-field class="gender-width">
      <mat-label>Giới tính</mat-label>
      <input
        matInput
        placeholder="Gender"
        value="{{ userProfile.gender }}"
        formControlName="gender"
      />
    </mat-form-field>
    <mat-form-field class="address-width align-right" style="margin-left: 10px">
      <mat-label>Địa chỉ</mat-label>
      <input
        matInput
        placeholder="PhoneNumber"
        value="{{ userProfile.address }}"
        formControlName="address"
      />
    </mat-form-field>
  </div>
  <div class="form-container">
    <mat-form-field class="full-width">
      <mat-label>Ngày sinh</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        value="{{ userProfile.dateOfBirth }}"
        formControlName="dateOfBirth"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="full-width align-right" style="margin-left: 10px">
      <mat-label>Số điện thoại</mat-label>
      <input
        matInput
        placeholder="PhoneNumber"
        value="{{ userProfile.phoneNumber }}"
        formControlName="phoneNumber"
      />
    </mat-form-field>
  </div>
  <mat-form-field class="profile-full-width">
    <mat-label>Comment</mat-label>
    <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
  </mat-form-field>
  <button
    class="btn btn-primary mt-3"
    type="submit"
    [disabled]="profileForm.invalid"
  >
    Submit
  </button>
</form>
