import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { Message, MessageSend } from 'src/app/models/messages';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { UserLogin } from 'src/app/models/user-login';
import { DBkeys } from 'src/app/services/db-keys';
import { Conversation } from 'src/app/models/chat/chat.user';

@Injectable({
  providedIn: 'root',
})
export class WebRTCSignalrChatService {
  private hubConnection!: signalR.HubConnection;
  public message: Message = new Message('', '', '', '', '', '');

  public messagesToChatSubject: BehaviorSubject<Message | null> =
    new BehaviorSubject<Message | null>(null);

  public messagesToCallSubject = new BehaviorSubject<MessageToCall | null>(
    null
  );
  public messagesToCall$ = this.messagesToCallSubject.asObservable();

  public incomingCall = false;
  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStoreManager
  ) {
    const avatar = this.localStorage.getDataObject<UserLogin>(
      DBkeys.CURRENT_USER
    )?.avatarUrl;
    if (avatar != '') {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`/chatHub?Avatar=${avatar}`)
        .configureLogging(signalR.LogLevel.None)
        .build();
      this.hubConnection.on(
        'ReceivePrivateMessage',
        (sender, avatalSender, messages) => {
          this.message = new Message(
            sender,
            messages.SenderId,
            messages.ReceiverName,
            avatalSender,
            messages.Message,
            messages.Timestamp
          );
          this.messagesToChatSubject.next(this.message);
        }
      );

      this.hubConnection.on('ReceiveMessage', (message) => {
        this.messagesToCallSubject.next(message);
      });

      this.hubConnection
        .start()
        .then(() => {})
        .catch((err) =>
          console.error('Error while starting connection: ' + err)
        );
    }
  }
  public sendOffer(
    receiverId: string,
    offer: RTCSessionDescriptionInit
  ): Promise<void> {
    return this.hubConnection
      .invoke('SendOffer', receiverId, { type: offer.type, sdp: offer.sdp })
      .catch((err) => console.error(err));
  }

  public sendAnswer(
    receiverId: string,
    answer: RTCSessionDescriptionInit
  ): Promise<void> {
    return this.hubConnection
      .invoke('SendAnswer', receiverId, {
        type: answer.type,
        sdp: answer.sdp,
      })
      .catch((err) => console.error(err));
  }

  public sendIceCandidate(
    receiverId: string,
    candidate: RTCIceCandidate
  ): Promise<void> {
    const iceCandidateMessage = {
      candidate: candidate.candidate,
      sdpMid: candidate.sdpMid,
      sdpMLineIndex: candidate.sdpMLineIndex,
    };

    return this.hubConnection
      .invoke('SendIceCandidate', receiverId, iceCandidateMessage)
      .catch((err) => console.error(err));
  }

  public sendMessage(receiverId: string, message: MessageSend): Promise<void> {
    return this.hubConnection
      .invoke('SendPrivateMessage', receiverId, message)
      .catch((err) => {
        throw err;
      });
  }

  public getUsersInteracted(): Observable<any[]> {
    return this.httpClient.get<any[]>(`/api/chat/GetUsersInteracted`);
  }

  public createNewConversations(conversation: Conversation): Observable<any> {
    return this.httpClient.post<Conversation>(
      `/api/conversation`,
      conversation
    );
  }

  public uploadFile(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(`api/chat/uploadvideofile`, formData);
  }

  public loadMessages(
    firstUserId: string,
    secondUserId: string,
    skipCount: number,
    takeCount: number
  ): Observable<any> {
    return this.httpClient.get<Message[]>(
      `/api/chat/GetChatOneByOne?firstUserId=${firstUserId}&secondUserId=${secondUserId}&page=${skipCount}&pageSize=${takeCount}`
    );
  }
}

export interface MessageToCall {
  type: string;
  data: any;
}
