import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '../product.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AccountService } from '../../user-profile/account.service';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { UserLogin } from 'src/app/models/user-login';
import { DBkeys } from 'src/app/services/db-keys';

@Component({
  selector: 'app-upload-product',
  templateUrl: './upload-product.component.html',
  styleUrls: ['./upload-product.component.css'],
})
export class UploadProductComponent implements OnInit {
  selectedCategory: string;
  selectedSubcategory: string;
  categories: string[] = [
    'Vehicle',
    'Fashion',
    'HomeAppliance',
    'ElectronicDevice',
  ];
  subcategories: string[] = [];
  productForm: FormGroup;
  dynamicFields: any[] = [];
  token: number = 0;
  loading = false; // Loading state

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private router: Router,
    private accountService: AccountService,
    private localStorage: LocalStoreManager
  ) {
    this.selectedCategory = '';
    this.selectedSubcategory = '';
    this.productForm = this.fb.group({});
    this.accountService.getUserProfile().subscribe((data) => {
      this.token = data.token!;
    });
  }
  avatarUser: string = '';

  ngOnInit() {
    this.productForm = this.fb.group({});
    const currentUser = this.localStorage.getDataObject<UserLogin>(
      DBkeys.CURRENT_USER
    );
    this.avatarUser = currentUser?.avatarUrl ?? '';
  }

  selectedFiles: File[] = [];

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.includes('video/')) {
          Swal.fire({
            title: 'Thông báo',
            text: 'Đăng bài có video sẽ bị giảm 1 token hiện có.',
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
        this.selectedFiles.push(files[i]);
      }
    }
  }

  removeFile(file: File) {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }

  onCategoryChange(event: any) {
    this.selectedCategory = event.value;
    this.selectedSubcategory = '';
    this.subcategories = this.getSubcategories(this.selectedCategory);
    this.dynamicFields = [];
    this.productForm.reset();
  }

  onSubcategoryChange(event: any) {
    this.selectedSubcategory = event.value;
    this.dynamicFields = this.getFieldsForCategory(
      this.selectedCategory,
      this.selectedSubcategory
    );
    this.updateForm();
  }

  shouldShowUploadForm(): boolean {
    return !!this.selectedCategory && !!this.selectedSubcategory;
  }

  getSubcategories(category: string): string[] {
    const subcategoryMap: any = {
      Vehicle: ['Car', 'Motorcycle', 'Bicycle'],
      Fashion: ['Men', 'Women', 'Kids'],
      HomeAppliance: ['Kitchen', 'Laundry', 'LivingRoom'],
      Motorcycle: ['Sport', 'Cruiser', 'Touring'],
      ElectronicDevice: ['Mobile', 'Laptop', 'Tablet'],
    };
    return subcategoryMap[category] || [];
  }

  getFieldsForCategory(category: string, subcategory: string): any[] {
    const commonFields = [
      {
        name: 'title',
        label: 'Tiêu đề đăng',
        placeholder: 'Tiêu đề đăng',
        type: 'input',
        validators: [Validators.required, , Validators.maxLength(50)],
      },
      {
        name: 'price',
        label: 'Giá bán',
        placeholder: 'Giá bán',
        type: 'input',
        validators: [
          Validators.required,
          Validators.pattern(/^\d+(\.\d{1,2})?$/),
        ],
      },
      {
        name: 'description',
        label: 'Mô tả chi tiết thêm',
        placeholder: 'Mô tả chi tiết thêm',
        type: 'textarea',
        validators: [],
      },
      {
        name: 'amount',
        label: 'Nhập số lượng',
        placeholder: 'Nhập số lượng',
        type: 'input',
        validators: [
          Validators.required,
          Validators.pattern(/^\d+$/),
          Validators.min(1),
          Validators.max(100),
        ],
      },
    ];
    const years = Array.from({ length: 10 }, (_, index) => 2015 + index);
    const fieldsMap: any = {
      Vehicle: {
        Car: [
          ...commonFields,
          {
            name: 'brand',
            label: 'Nhãn hiệu',
            placeholder: 'Nhãn hiệu',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'year',
            label: 'Năm sản xuất',
            placeholder: 'Chọn năm sản xuất',
            type: 'select',
            options: years,
            validators: [Validators.required, Validators.pattern(/^\d+$/)],
          },
          {
            name: 'color',
            label: 'Màu sắc',
            placeholder: 'Chọn màu sắc',
            type: 'input',
            validators: [Validators.required],
          },
        ],
        Motorcycle: [
          ...commonFields,
          {
            name: 'brand',
            label: 'Nhãn hiệu',
            placeholder: 'Nhãn hiệu',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'year',
            label: 'Năm sản xuất',
            placeholder: 'Chọn năm sản xuất',
            type: 'input',
            validators: [Validators.required],
          },
        ],
        Bicycle: [
          ...commonFields,
          {
            name: 'type',
            label: 'Loại xe đạp',
            placeholder: 'Loại xe đạp',
            type: 'input',
            validators: [Validators.required],
          },
        ],
      },
      Fashion: {
        Men: [
          ...commonFields,
          {
            name: 'size',
            label: 'Kích thước',
            placeholder: 'Chọn kích thước',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'color',
            label: 'Màu sắc',
            placeholder: 'Chọn màu sắc',
            type: 'input',
            validators: [Validators.required],
          },
        ],
        Women: [
          ...commonFields,
          {
            name: 'size',
            label: 'Kích thước',
            placeholder: 'Chọn kích thước',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'color',
            label: 'Màu sắc',
            placeholder: 'Chọn màu sắc',
            type: 'input',
            validators: [Validators.required],
          },
        ],
        Kids: [
          ...commonFields,
          {
            name: 'size',
            label: 'Kích thước',
            placeholder: 'Chọn kích thước',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'color',
            label: 'Màu sắc',
            placeholder: 'Chọn màu sắc',
            type: 'input',
            validators: [Validators.required],
          },
        ],
      },
      HomeAppliance: {
        Kitchen: [
          ...commonFields,
          {
            name: 'brand',
            label: 'Nhãn hiệu',
            placeholder: 'Nhãn hiệu',
            type: 'input',
            validators: [Validators.required],
          },
        ],
        Laundry: [
          ...commonFields,
          {
            name: 'brand',
            label: 'Nhãn hiệu',
            placeholder: 'Nhãn hiệu',
            type: 'input',
            validators: [Validators.required],
          },
        ],
        LivingRoom: [
          ...commonFields,
          {
            name: 'brand',
            label: 'Nhãn hiệu',
            placeholder: 'Nhãn hiệu',
            type: 'input',
            validators: [Validators.required],
          },
        ],
      },
      ElectronicDevice: {
        Mobile: [
          ...commonFields,
          {
            name: 'brand',
            label: 'Nhãn hiệu',
            placeholder: 'Nhãn hiệu',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'model',
            label: 'Model',
            placeholder: 'Model',
            type: 'input',
            validators: [Validators.required],
          },
        ],
        Laptop: [
          ...commonFields,
          {
            name: 'brand',
            label: 'Nhãn hiệu',
            placeholder: 'Nhãn hiệu',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'model',
            label: 'Model',
            placeholder: 'Model',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'OperatingSystem',
            label: 'Hệ điều hành',
            placeholder: 'Hệ điều hành',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'Resolution',
            label: 'Độ phân giải',
            placeholder: 'Độ phân giải',
            type: 'input',
            validators: [Validators.required],
          },
        ],
        Tablet: [
          ...commonFields,
          {
            name: 'brand',
            label: 'Nhãn hiệu',
            placeholder: 'Nhãn hiệu',
            type: 'input',
            validators: [Validators.required],
          },
          {
            name: 'model',
            label: 'Model',
            placeholder: 'Model',
            type: 'input',
            validators: [Validators.required],
          },
        ],
      },
    };

    return fieldsMap[category]?.[subcategory] || [];
  }

  updateForm() {
    const controls: { [key: string]: any } = {};
    this.dynamicFields.forEach((field) => {
      controls[field.name] = ['', field.validators];
    });
    this.productForm = this.fb.group(controls);
  }

  onSubmit() {
    if (this.selectedFiles.length === 0) {
      return;
    }
    if (this.productForm.valid) {
      const formData = new FormData();
      formData.append('Title', this.productForm.value.title);
      formData.append('Avatar', this.avatarUser);
      formData.append('Description', this.productForm.value.description);
      formData.append('Price', this.productForm.value.price.toString());
      formData.append('Amount', this.productForm.value.amount.toString());
      formData.append('Category', this.selectedCategory);
      switch (this.selectedSubcategory) {
        case 'Car':
          formData.append('Car.Color', this.productForm.value.color.toString());
          formData.append('Car.Year', this.productForm.value.year);
          formData.append('Car.Brand', this.productForm.value.brand);
          break;
        case 'Motorcycle':
          formData.append(
            'Motorcycle.Color',
            this.productForm.value.color.toString()
          );
          formData.append('Motorcycle.Year', this.productForm.value.year);
          formData.append('Motorcycle.Brand', this.productForm.value.brand);
          break;
        case 'Bicycle':
          formData.append('Bicycle.Type', this.productForm.value.type);
          break;
        case 'Men':
        case 'Women':
        case 'Kids':
          formData.append('Fashion.Size', this.productForm.value.size);
          formData.append('Fashion.Color', this.productForm.value.color);
          break;
        case 'Kitchen':
        case 'Laundry':
        case 'LivingRoom':
          formData.append('Appliance.Brand', this.productForm.value.brand);
          break;
        case 'Laptop':
          formData.append(
            'ElectronicDevice.Brand',
            this.productForm.value.brand
          );
          formData.append(
            'ElectronicDevice.Model',
            this.productForm.value.model
          );
          formData.append(
            'ElectronicDevice.OperatingSystem',
            this.productForm.value.model
          );
          formData.append(
            'ElectronicDevice.Resolution',
            this.productForm.value.model
          );
          break;
        case 'Mobile':
        case 'Tablet':
          formData.append(
            'ElectronicDevice.Brand',
            this.productForm.value.brand
          );
          formData.append(
            'ElectronicDevice.Model',
            this.productForm.value.model
          );
          break;
      }
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append('Files', this.selectedFiles[i]);
      }
      if (this.selectedFiles.length == 0) {
        formData.append('Files', null!);
      }
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];
        if (file.type.includes('video/') && this.token <= 0) {
          Swal.fire({
            title: 'Thông báo',
            text: 'Bạn cần nạp thêm token để tiếp tục',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Nạp Token',
            cancelButtonText: 'Đóng',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/user/upgrade-account']);
            }
          });
          return;
        }
      }
      this.loading = true;
      this.productService.uploadProduct(formData).subscribe(
        (response) => {
          this.loading = false;
          Swal.fire({
            title: 'Thành công!',
            text: 'Sản phẩm đã được đăng thành công.',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            this.router.navigate(['/']);
          });
        },
        (error) => {
          this.loading = false;
          Swal.fire({
            title: 'Lỗi!',
            text: 'Vui lòng chọn kích thước ảnh hoặc video < 20Mb',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      );
    }
  }
}
