import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserEditProfile } from 'src/app/models/user/user.edit';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { DialogElementsComponent } from './dialog-elements/dialog-elements.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { NotificationService } from '../notification/notification.service';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { UserLogin } from 'src/app/models/user-login';
import { DBkeys } from 'src/app/services/db-keys';
import { AccountService } from './account.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserProfileComponent,
    },
  ],
})
export class UserProfileComponent implements OnInit {
  selectedTab: string = 'profile';
  file: string = 'assets/images/demo/banner1.png';
  zoom: number = 1;
  public selectedFile: File | null = null;
  public isImageSelected: boolean = false;
  public imagePreview: string | ArrayBuffer | null = null;
  public showConfirmationModal: boolean = false;
  public errorFileSize: boolean = false;
  public isAdmin: boolean = false;
  writeValue(_file: string): void {
    this.file = _file;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = (fileUrl: string) => {};

  onTouched = () => {};

  disabled: boolean = false;
  selectTab(tab: string) {
    this.selectedTab = tab;
  }
  onZoomChange(event: any): void {
    this.zoom = event.value;
    console.log(event.value);
  }

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private localStorage: LocalStoreManager,
    private accountService: AccountService,
    public dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.http.get<UserEditProfile>('api/UserInfo').subscribe((data) => {
      this.file = data.profileImageUrl;
    });
    this.isAdmin = this.localStorage
      .getDataObject<UserLogin>(DBkeys.CURRENT_USER)!
      .roles.includes('Admin');
    console.log(this.isAdmin);
  }
  onFileChange(event: any) {
    const files = event.target.files as FileList;
    if (files.length > 0) {
      const file = files[0];
      this.convertFileToBase64(file).then((base64) => {
        this.resetInput();
        this.openAvatarEditor(base64).subscribe((result) => {
          if (result) {
            this.file = result;
            this.uploadImage(result);
          }
        });
      });
    }
  }

  uploadImage(ProfileImageUrl: string): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http
      .put('/api/UserInfo/changeAvatar', JSON.stringify(ProfileImageUrl), {
        headers,
      })
      .subscribe((response) => {
        Swal.fire({
          title: 'Thành công!',
          text: 'Thay đổi ảnh đại diện thành công.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      });
  }
  convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  openAvatarEditor(image: string): Observable<any> {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      maxWidth: '80vw',
      maxHeight: '60vh',
      data: image,
    });

    return dialogRef.afterClosed();
  }

  resetInput() {
    const input = document.getElementById(
      'avatar-input-file'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  showErrorDialog() {
    this.dialog.open(DialogElementsComponent);
  }
  message: string = '';
  receiverName: string = '';
  userNameReceiver: string = '';
  token: number = 0;
  addToken() {
    this.accountService.AddToken(this.userNameReceiver, this.token).subscribe(
      (response) => {
        Swal.fire({
          title: 'Thành công!',
          text: `Nạp thành công ${this.token} token cho ${this.userNameReceiver}`,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        this.notificationService.AdminSendMessage(
          this.userNameReceiver,
          `Bạn vừa nhận ${this.token} token từ Admin`
        );
      },
      (error) => {
        Swal.fire('Lỗi!', `${this.userNameReceiver} Not found!`, 'error');
      }
    );
  }
  sendNotification(): void {
    if (this.receiverName == '') {
      this.notificationService
        .AdminSendPublicMessage('user', this.message)
        .then(() => {
          //this.message = '';
        })
        .catch((error) => {
          console.error('Error sending notification:', error);
        });
    } else {
      this.notificationService
        .AdminSendMessage(this.receiverName, this.message)
        .then(() => {
          this.message = '';
        })
        .catch((error) => {
          console.error('Error sending notification:', error);
        });
    }
  }
}
