<div class="container">
    <div class="sidebar">
        <div class="avatar-container">
            <div class="image-container" *ngIf="file; else placeholder" [ngClass]="{'box-shadow-avatar': !!file}"
                [ngStyle]="{backgroundImage: 'url(' + file + ')'}">
            </div>
            <ng-template #placeholder>
                <div class="image-container avatar-placeholder">
                </div>
            </ng-template>
            <label for="avatar-input-file">
                <div class="icon-wrapper">
                    <input type="file" id="avatar-input-file" accept="image/*" (change)="onFileChange($event)" />
                    <mat-icon class="icon" fontIcon="edit"></mat-icon>
                </div>
            </label>
        
        </div>
        <button (click)="selectTab('profile')">Profile</button>
        <button (click)="selectTab('password')">Password</button>
        <button *ngIf="this.isAdmin"  (click)="selectTab('sendNotification')">Send Notification</button>
        <button *ngIf="this.isAdmin" (click)="selectTab('addToken')">Add Token</button>
    </div>
    <div class="content">
        <app-profile *ngIf="selectedTab === 'profile'"></app-profile>
        <app-password *ngIf="selectedTab === 'password'"></app-password>
        <div *ngIf="selectedTab === 'sendNotification'">
            <mat-form-field style="width: 100%;">
                <mat-label>Nhập UserName nguời nhận</mat-label>
                <input type="text" matInput [(ngModel)]="receiverName" placeholder="UserName nguời nhận" >
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>Nhập thông báo</mat-label>
                <textarea style="height: 200px;" matInput [(ngModel)]="message" placeholder="Nhập nội dung thông báo"></textarea>
            </mat-form-field>
            <div>
                <button class="btn btn-primary mt-3" (click)="sendNotification()">Send</button>
            </div>
        </div>
        <div *ngIf="selectedTab === 'addToken'">
            <mat-form-field style="width: 50%;">
                <mat-label>Nhập UserName nguời nhận</mat-label>
                <input type="text" matInput [(ngModel)]="userNameReceiver" placeholder="UserName nguời nhận">
            </mat-form-field>
            <mat-form-field style="width: 50%;">
                <mat-label>Nhập số token nạp</mat-label>
                <input type="number" matInput [(ngModel)]="token" placeholder="Nhập số token nạp">
            </mat-form-field>
            <div>
                <button class="btn btn-primary mt-3" (click)="addToken()">Send</button>
            </div>
        </div>
    </div>
</div>
    <ng-template #placeholder>
        <div class="image-container avatar-placeholder">
        </div>
    </ng-template>
