export class Payment {
  paymentContent: string;
  paymentCurrency: string;
  paymentRefId: string;
  requiredAmount: number;
  paymentLanguage: string;
  merchantId: number;
  paymentDestinationId: string;

  constructor(
    paymentContent: string,
    paymentCurrency: string,
    paymentRefId: string,
    requiredAmount: number,
    paymentLanguage: string,
    merchantId: number,
    paymentDestinationId: string
  ) {
    this.paymentContent = paymentContent;
    this.paymentCurrency = paymentCurrency;
    this.paymentRefId = paymentRefId;
    this.requiredAmount = requiredAmount;
    this.paymentLanguage = paymentLanguage;
    this.merchantId = merchantId;
    this.paymentDestinationId = paymentDestinationId;
  }
}
