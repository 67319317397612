import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-category-bar',
  templateUrl: './category-bar.component.html',
  styleUrls: ['./category-bar.component.css'],
})
export class CategoryBarComponent {
  categories = [
    { icon: 'fas fa-female', label: 'Women' },
    { icon: 'fas fa-male', label: 'Men' },
    { icon: 'fas fa-mobile-alt', label: 'Electronics' },
    { icon: 'fas fa-robot', label: 'Toys' },
    { icon: 'fas fa-gamepad', label: 'Gaming' },
    { icon: 'fas fa-handbag', label: 'Handbags' },
    { icon: 'fas fa-home', label: 'Home' },
    { icon: 'fas fa-clock', label: 'Vintage' },
    { icon: 'fas fa-spa', label: 'Beauty' },
    { icon: 'fas fa-baby', label: 'Kids' },
    { icon: 'fas fa-football-ball', label: 'Sports' },
    { icon: 'fas fa-handshake', label: 'Handmade' },
    { icon: 'fas fa-briefcase', label: 'Office' },
    { icon: 'fas fa-paw', label: 'Pet' },
  ];
  visibleItemsCount!: number;
  constructor() {
    this.updateVisibleItemsCount(window.innerWidth);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItemsCount(event.target.innerWidth);
  }

  updateVisibleItemsCount(windowWidth: number) {
    if (windowWidth < 640) {
      this.visibleItemsCount = 5;
    } else if (windowWidth < 768) {
      this.visibleItemsCount = 9;
    } else {
      this.visibleItemsCount = this.categories.length;
    }
  }
}
