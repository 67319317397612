import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(
    value: string,
    limit: number = 100,
    trail: string = ' ....'
  ): string {
    if (!value) return '';
    const isFile =
      /\.(jpg|jpeg|png|gif|mp4|webm|ogg|svg)$/i.test(value) &&
      value.startsWith('https://identity-hka.click/');
    const anotherFile = value.startsWith(
      'https://identity-hka.click/wwwroot/user/uploads'
    );
    const isFileZip = /\.(zip|rar)$/i.test(value);
    if (isFileZip || anotherFile) {
      return 'Đã gửi tệp tin';
    }
    const isDocument =
      /\.(doc|docx|pdf|txt|xls|xlsx)$/i.test(value) &&
      value.startsWith('https://identity-hka.click/');
    if (isFile || isDocument) {
      return 'Đã gửi file tài liệu';
    }

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
