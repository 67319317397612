import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { UserPassword } from 'src/app/models/user/user.password';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup = new FormGroup({});
  userProfile: UserPassword = new UserPassword();
  constructor(
    private formBuider: FormBuilder,
    private snackBar: MatSnackBar,
    private accountService: AccountService
  ) {}
  ngOnInit(): void {
    this.passwordForm = this.formBuider.group({
      OldPassword: ['', Validators.required],
      NewPassword: ['', Validators.required],
      ConfirmNewPassword: ['', Validators.required],
    });
  }
  onSubmit() {
    if (this.passwordForm.valid) {
      let userPassword: UserPassword = this.passwordForm.value;
      if (userPassword) {
        this.accountService.changePassword(userPassword).subscribe((next) => {
          this.openSnackBar('Password updated successfully!');
        });
      }
    }
  }
  openSnackBar(message: string, action: string = 'Close') {
    const config = new MatSnackBarConfig();
    config.duration = 2000; // Độ dài hiển thị của snackbar (ms)
    config.horizontalPosition = 'right';
    config.verticalPosition = 'top';
    this.snackBar.open(message, action, config);
  }
}
