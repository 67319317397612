export class UserProfile {
  constructor(
    public id: string = '',
    public userName: string = '',
    public address: string = '',
    public addressList: string[] = [],
    public gender: string = '',
    public phoneNumber: string = '',
    public email: string = '',
    public fullName: string = '',
    public rating: number = 0,
    public reviews: number = 0,
    public profileImageUrl: string = '',
    public token: number | null = null,
    public dateOfBirth: Date = new Date()
  ) {}
}
