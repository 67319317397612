import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow, parseISO, addHours } from 'date-fns';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    const date = parseISO(value);
    const adjustedDate = addHours(date, 7);
    return formatDistanceToNow(adjustedDate, { addSuffix: true }).replace(
      ' ago',
      ''
    );
  }
}
