<div style="padding: 20px;">
    <img #imageElement id="image" height="300" [src]="sanitizedUrl">
    <div class="crop-actions">
        <button style="margin-right: 1rem" mat-flat-button (click)="reset()">
            Reset
        </button>
        <button color="primary" mat-flat-button (click)="crop()">
            Ok
        </button>
    </div>
</div>