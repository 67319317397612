import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './services/auth-guard';
import { SettingsComponent } from './components/settings/settings.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthService } from './services/auth.service';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
//import { ChatComponent } from './components/chat/chat.component';
import { ProductDetailComponent } from './components/product/product-detail/product-detail.component';
import { UpgradeAccountComponent } from './components/user-profile/upgrade-account/upgrade-account.component';
import { CheckoutSubscriptionComponent } from './components/checkout/checkout-subscription/checkout-subscription.component';
import { UploadProductComponent } from './components/product/upload-product/upload-product.component';
import { PostManagementComponent } from './components/post-management/post-management.component';
import { AdminNotificationComponent } from './components/notification/admin-notification/admin-notification.component';
import { ChatComponent } from './components/chat/chat.component';
import { CartComponent } from './components/cart/cart.component';
import { VideoChatComponent } from './components/chat/video-chat/video-chat.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'Home',
  },
  { path: 'listing/:id', component: ProductDetailComponent },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    title: 'Settings',
  },
  // {
  //   path: 'cart',
  //   component: CartComponent,
  //   canActivate: [AuthGuard],
  //   title: 'Cart',
  // },
  {
    path: 'mypost/listing',
    component: PostManagementComponent,
    canActivate: [AuthGuard],
  },
  { path: 'video-chat/:userId', component: VideoChatComponent },
  {
    path: 'account/user',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user/upgrade-account',
    component: UpgradeAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'upload-product',
    component: UploadProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthGuard],
  },
  { path: 'checkout/subscription', component: CheckoutSubscriptionComponent },
  { path: '**', component: NotFoundComponent, title: 'Page Not Found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService],
})
export class AppRoutingModule {}
