import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductService } from '../product.service';
import { GetByIdProduct } from 'src/app/models/product/product.getbyid';
import { ActivatedRoute, Router } from '@angular/router';
import { WebRTCSignalrChatService } from '../../chat/webrtc-signalr-chat.service';
import { CartService } from '../../cart/cart.service';
import { DBkeys } from 'src/app/services/db-keys';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { Comment } from 'src/app/models/comment/comment';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css'],
})
export class ProductDetailComponent implements OnInit {
  private touchStartX: number = 0;
  private touchEndX: number = 0;
  product!: GetByIdProduct;
  cartItems: any[] = [];
  currentFileIndex: number = 0;
  isInCart: boolean = false;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    public cartService: CartService,
    private authService: AuthService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const idFromUrl = params['id'];
      if (idFromUrl) {
        const idSegments = idFromUrl.split('-');
        const productId = idSegments[idSegments.length - 1];
        this.productService.getProduct(productId).subscribe((product) => {
          this.product = product;
        });
      }
    });
  }

  addToCart(): void {
    const item = {
      id: this.product.id, // Ensure you have an ID property in your product
      image: this.product.filesPathUrl[0],
      name: this.product.title,
      price: this.product.price,
    };
    this.cartService.addItem(item);
    this.cartService.showCart();
    this.isInCart = true;
  }

  navigateToCart(): void {
    this.router.navigate(['/cart']);
  }

  chatWithSeller(userId: string, userAvatar: string, userName: string): void {
    const currentUser = JSON.parse(
      localStorage.getItem(DBkeys.CURRENT_USER) || '{}'
    );
    if (!currentUser.userId) {
      Swal.fire({
        title: 'You need to log in',
        text: 'You need to log in to access this page.',
        icon: 'warning',
        showDenyButton: true,
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#f0ad4e',
        confirmButtonText: 'Password Flow',
        denyButtonText: 'Client Credentials Flow',
      }).then((result) => {
        if (result.isConfirmed) {
          this.authService.oicdPasswordFlow();
        } else if (result.isDenied) {
          this.authService.openLoginPopup();
        }
      });
      return;
    }
    this.router.navigate(['/chat'], {
      queryParams: { userId: userId },
      state: { userId: userId, userAvatar: userAvatar, userName: userName },
    });
  }

  isImage(url: string): boolean {
    return url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  isVideo(url: string): boolean {
    return url.toLowerCase().match(/\.(mp4|ogg|webm)$/) != null;
  }

  changeMainFile(index: number): void {
    this.currentFileIndex = index;
  }

  previousFile(): void {
    this.currentFileIndex =
      (this.currentFileIndex - 1 + this.product.filesPathUrl.length) %
      this.product.filesPathUrl.length;
  }

  nextFile(): void {
    this.currentFileIndex =
      (this.currentFileIndex + 1) % this.product.filesPathUrl.length;
  }
  handleTouchStart(event: TouchEvent): void {
    this.touchStartX = event.changedTouches[0].screenX;
  }

  handleTouchMove(event: TouchEvent): void {
    this.touchEndX = event.changedTouches[0].screenX;
  }

  handleTouchEnd(): void {
    if (this.touchStartX - this.touchEndX > 50) {
      this.nextFile();
    }
    if (this.touchEndX - this.touchStartX > 50) {
      this.previousFile();
    }
  }
}
