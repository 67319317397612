import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import Swal from 'sweetalert2';
import { WebRTCSignalrChatService } from '../webrtc-signalr-chat.service';

const mediaConstraints = {
  audio: true,
  video: { width: 1280, height: 720 },
};

@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.css'],
})
export class VideoChatComponent implements OnInit, AfterViewInit {
  @ViewChild('localVideo') localVideo!: ElementRef;
  @ViewChild('remoteVideo') remoteVideo!: ElementRef;
  private localStream: MediaStream | null = null;
  private peerConnection: RTCPeerConnection | null = null;
  isCameraOn = true;
  isMicrophoneOn = true;
  isSharingScreen = false;

  private currentCameraIndex = 0;
  receiverId: string = '';
  receiverName: string = '';
  senderIdCalling: string = '';
  senderNameCalling: string = '';

  constructor(public webRTCSignalrChatService: WebRTCSignalrChatService) {}

  ngOnInit(): void {
    this.receiverId = sessionStorage.getItem('receiverIdToCall')!;
    this.receiverName = sessionStorage.getItem('receiverNameToCall')!;
    this.senderIdCalling = sessionStorage.getItem('SenderIdCalling')!;
    this.senderNameCalling = sessionStorage.getItem('SenderNameCalling')!;
    this.addIncomingMessageHandler();
  }

  ngAfterViewInit(): void {
    this.requestMediaDevices();
  }

  private async requestMediaDevices(): Promise<void> {
    try {
      this.localStream = await navigator.mediaDevices.getUserMedia(
        mediaConstraints
      );
      this.localVideo.nativeElement.srcObject = this.localStream;
      console.log('Local stream started:', this.localStream);
    } catch (e) {
      console.error('Error accessing media devices:', e);
    }
  }

  private addIncomingMessageHandler(): void {
    this.webRTCSignalrChatService.messagesToCall$.subscribe((msg) => {
      if (msg && msg.data) {
        const type = msg.type;
        const sdp = msg.data.Sdp;
        if (type === 'offer' || type === 'answer') {
          try {
            const description = new RTCSessionDescription({
              type: type as RTCSessionDescriptionInit['type'],
              sdp: sdp || '',
            });

            switch (type) {
              case 'offer':
                console.log('Handling offer');
                this.handleOfferMessage(description);
                break;
              case 'answer':
                console.log('Handling answer');
                this.handleAnswerMessage(description);
                break;
              default:
                console.log('Unhandled type:', type);
            }
          } catch (error) {
            console.error('Error creating RTCSessionDescription:', error);
          }
        } else if (type === 'ice-candidate') {
          this.handleICECandidateMessage(msg.data);
        } else if (type === 'hangup') {
          this.closeVideoCall();
        } else {
          console.log('Unknown message type:', type);
        }
      } else {
        console.log('Invalid message format:', msg);
      }
    });
  }

  async call(): Promise<void> {
    this.createPeerConnection();

    this.localStream!.getTracks().forEach((track) =>
      this.peerConnection!.addTrack(track, this.localStream!)
    );

    try {
      const offer: RTCSessionDescriptionInit =
        await this.peerConnection!.createOffer();
      await this.peerConnection!.setLocalDescription(offer);
      this.webRTCSignalrChatService.sendOffer(this.receiverId, offer);
      console.log('Sent offer:', offer);
    } catch (err) {
      console.error('Error creating offer: ', err);
    }
  }

  private createPeerConnection(): void {
    if (this.peerConnection) {
      this.closeVideoCall();
    }
    this.peerConnection = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302',
        },
        {
          urls: 'turn:14.225.211.218:3478',
          username: 'hung2002',
          credential: 'hung2002',
        },
      ],
    });
    this.peerConnection.onicecandidate = this.handleICECandidateEvent;
    this.peerConnection.oniceconnectionstatechange =
      this.handleICEConnectionStateChangeEvent;
    this.peerConnection.onsignalingstatechange =
      this.handleSignalingStateChangeEvent;
    this.peerConnection.ontrack = this.handleTrackEvent;
    console.log('Peer connection created:', this.peerConnection);
  }

  private handleICECandidateEvent = (event: RTCPeerConnectionIceEvent) => {
    if (event.candidate) {
      this.webRTCSignalrChatService.sendIceCandidate(
        this.receiverId,
        event.candidate
      );
      console.log('Sent ICE candidate:', event.candidate);
    }
  };

  private handleSignalingStateChangeEvent = (event: Event) => {
    switch (this.peerConnection!.signalingState) {
      case 'closed':
        this.closeVideoCall();
        break;
    }
  };

  private handleICEConnectionStateChangeEvent = (event: Event) => {
    switch (this.peerConnection!.iceConnectionState) {
      case 'closed':
      case 'failed':
      case 'disconnected':
        this.closeVideoCall();
        break;
    }
  };

  private handleTrackEvent = (event: RTCTrackEvent) => {
    if (event.streams && event.streams[0]) {
      console.log('Remote stream detected:', event.streams[0]);
      const remoteVideoElement = this.remoteVideo.nativeElement;
      remoteVideoElement.srcObject = event.streams[0];
      remoteVideoElement.onloadedmetadata = () => {
        console.log('Remote video loaded metadata');
        remoteVideoElement.play();
      };
      console.log('Remote stream added to video element:', remoteVideoElement);
    } else {
      console.log('No streams available in track event:', event);
    }
  };

  private closeVideoCall(): void {
    if (this.peerConnection) {
      this.peerConnection.ontrack = null;
      this.peerConnection.onicecandidate = null;
      this.peerConnection.oniceconnectionstatechange = null;
      this.peerConnection.onsignalingstatechange = null;

      this.peerConnection
        .getTransceivers()
        .forEach((transceiver) => transceiver.stop());
      this.peerConnection.close();
      this.peerConnection = null;
      this.webRTCSignalrChatService.incomingCall = false; // Đặt lại trạng thái cuộc gọi đến
    }
  }

  handleOfferMessage(msg: RTCSessionDescriptionInit): void {
    if (!this.peerConnection) {
      this.createPeerConnection();
    }
    this.peerConnection!.setRemoteDescription(new RTCSessionDescription(msg))
      .then(() => {
        return navigator.mediaDevices.getUserMedia(mediaConstraints);
      })
      .then((stream) => {
        this.localStream = stream;
        this.localVideo.nativeElement.srcObject = this.localStream;

        this.localStream!.getTracks().forEach((track) => {
          this.peerConnection!.addTrack(track, this.localStream!);
        });
      })
      .then(() => {
        this.webRTCSignalrChatService.incomingCall = true;
        console.log('Incoming call, waiting for user response...');
      })
      .catch(this.handleGetUserMediaError);
  }

  private handleAnswerMessage(msg: RTCSessionDescriptionInit): void {
    this.peerConnection!.setRemoteDescription(
      new RTCSessionDescription(msg)
    ).catch(this.handleGetUserMediaError);
  }

  private handleICECandidateMessage(msg: any): void {
    console.log('Received ICE candidate message:', msg);
    const candidate = msg.Candidate;
    const sdpMid = msg.SdpMid;
    const sdpMLineIndex = msg.SdpMLineIndex;
    const iceCandidate = new RTCIceCandidate({
      candidate,
      sdpMid,
      sdpMLineIndex,
    });
    this.peerConnection!.addIceCandidate(iceCandidate).then(() =>
      console.log('ICE candidate added successfully')
    );
  }

  private handleGetUserMediaError(e: Error): void {
    switch (e.name) {
      case 'NotFoundError':
        alert(
          'Unable to open your call because no camera and/or microphone were found.'
        );
        break;
      case 'SecurityError':
      case 'PermissionDeniedError':
        break;
      default:
        alert('Error opening your camera and/or microphone: ' + e.message);
        break;
    }
  }

  handleIncomingCall(): void {
    this.receiverId = sessionStorage.getItem('SenderIdCalling')!;
    if (this.peerConnection && this.localStream) {
      console.log('handleIncomingCall');
      this.localStream.getTracks().forEach((track) => {
        const existingSenders = this.peerConnection!.getSenders();
        const hasSender = existingSenders.some(
          (sender) => sender.track === track
        );
        if (!hasSender) {
          this.peerConnection!.addTrack(track, this.localStream!);
        }
      });
      this.peerConnection!.createAnswer()
        .then((answer) => {
          this.peerConnection!.setLocalDescription(answer).then(() => {
            this.webRTCSignalrChatService.sendAnswer(
              this.receiverId,
              this.peerConnection!.localDescription!
            );
            this.webRTCSignalrChatService.incomingCall = false;
          });
        })
        .catch(this.handleGetUserMediaError);
    }
  }

  rejectCall(): void {
    this.closeVideoCall();
    this.webRTCSignalrChatService.incomingCall = false; // Tắt trạng thái cuộc gọi đến
  }

  toggleCamera(): void {
    if (!this.localStream) {
      Swal.fire({
        title: 'Error',
        text: 'Local stream not initialized.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );

      if (videoDevices.length > 1) {
        this.currentCameraIndex =
          (this.currentCameraIndex + 1) % videoDevices.length;
        const newConstraints = {
          video: {
            deviceId: { exact: videoDevices[this.currentCameraIndex].deviceId },
            width: 1280,
            height: 720,
          },
        };
        navigator.mediaDevices
          .getUserMedia(newConstraints)
          .then((newStream) => {
            const videoTrack = newStream.getVideoTracks()[0];
            const sender = this.peerConnection!.getSenders().find(
              (s) => s.track!.kind === 'video'
            );
            sender!.replaceTrack(videoTrack);

            this.localStream!.getTracks().forEach((track) => track.stop());
            this.localStream = newStream;
            this.localVideo.nativeElement.srcObject = this.localStream;
          });
      }
    });
  }

  toggleCameraOnOff(): void {
    if (this.localStream) {
      this.isCameraOn = !this.isCameraOn;
      this.localStream.getVideoTracks().forEach((track) => {
        track.enabled = this.isCameraOn;
      });
      const videoTrack = this.localStream.getVideoTracks()[0];
      if (videoTrack) {
        const sender = this.peerConnection!.getSenders().find(
          (s) => s.track?.kind === 'video'
        );
        if (sender) {
          sender.replaceTrack(videoTrack);
        }
      }
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Local stream not initialized.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }

  async toggleMicrophone(): Promise<void> {
    if (this.localStream) {
      this.isMicrophoneOn = !this.isMicrophoneOn;
      this.localStream.getAudioTracks().forEach((track) => {
        track.enabled = this.isMicrophoneOn;
      });
      const audioTrack = this.localStream.getAudioTracks()[0];
      if (audioTrack) {
        const sender = this.peerConnection!.getSenders().find(
          (s) => s.track?.kind === 'audio'
        );
        if (sender) {
          sender.replaceTrack(audioTrack);
        }
      }
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Local stream not initialized.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }

  shareScreen(): void {
    if (this.isSharingScreen) {
      this.stopSharingScreen();
    } else {
      if (this.isMicrophoneOn) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((micStream) => {
            return navigator.mediaDevices
              .getDisplayMedia({ video: true, audio: true })
              .then((screenStream) => {
                this.localStream = screenStream;

                const audioContext = new AudioContext();
                const destination = audioContext.createMediaStreamDestination();
                const screenAudio =
                  audioContext.createMediaStreamSource(screenStream);
                const microphoneAudio =
                  audioContext.createMediaStreamSource(micStream);

                screenAudio.connect(destination);
                microphoneAudio.connect(destination);

                const combinedAudioStream = destination.stream;
                const videoTrack = screenStream.getVideoTracks()[0];
                const audioTrack = combinedAudioStream.getAudioTracks()[0];

                const videoSender = this.peerConnection!.getSenders().find(
                  (s) => s.track!.kind === 'video'
                );
                const audioSender = this.peerConnection!.getSenders().find(
                  (s) => s.track!.kind === 'audio'
                );

                if (videoSender) {
                  videoSender.replaceTrack(videoTrack);
                } else {
                  this.peerConnection!.addTrack(videoTrack, screenStream);
                }

                if (audioSender) {
                  audioSender.replaceTrack(audioTrack);
                } else {
                  this.peerConnection!.addTrack(
                    audioTrack,
                    combinedAudioStream
                  );
                }

                this.localVideo.nativeElement.srcObject = new MediaStream([
                  videoTrack,
                ]);

                this.isSharingScreen = true;

                videoTrack.onended = () => {
                  this.stopSharingScreen();
                };
              });
          });
      } else {
        navigator.mediaDevices
          .getDisplayMedia({ video: true, audio: true })
          .then((stream) => {
            this.localStream = stream;
            const videoTrack = stream.getVideoTracks()[0];
            const sender = this.peerConnection!.getSenders().find(
              (s) => s.track!.kind === 'video'
            );
            if (sender) {
              sender.replaceTrack(videoTrack);
            } else {
              this.peerConnection!.addTrack(videoTrack, stream);
            }
            const audioTrack = stream.getAudioTracks()[0];
            if (audioTrack) {
              const audioSender = this.peerConnection!.getSenders().find(
                (s) => s.track!.kind === 'audio'
              );
              if (audioSender) {
                audioSender.replaceTrack(audioTrack);
              } else {
                this.peerConnection!.addTrack(audioTrack, stream);
              }
            }
            this.localVideo.nativeElement.srcObject = new MediaStream([
              videoTrack,
            ]);
            this.isSharingScreen = true;

            videoTrack.onended = () => {
              this.stopSharingScreen();
            };
          });
      }
    }
  }
  stopSharingScreen(): void {
    const isMicrophoneCurrentlyOn = this.isMicrophoneOn;

    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => track.stop());
    }
    this.requestMediaDevices().then(() => {
      const videoTrack = this.localStream!.getVideoTracks()[0];
      const sender = this.peerConnection!.getSenders().find(
        (s) => s.track!.kind === 'video'
      );
      if (sender) {
        sender.replaceTrack(videoTrack);
      } else {
        this.peerConnection!.addTrack(videoTrack, this.localStream!);
      }

      const audioTrack = this.localStream!.getAudioTracks()[0];
      if (audioTrack) {
        const audioSender = this.peerConnection!.getSenders().find(
          (s) => s.track!.kind === 'audio'
        );
        if (audioSender) {
          audioSender.replaceTrack(audioTrack);
        } else {
          this.peerConnection!.addTrack(audioTrack, this.localStream!);
        }
        audioTrack.enabled = isMicrophoneCurrentlyOn;
      }
      this.localVideo.nativeElement.srcObject = this.localStream;
      this.isSharingScreen = false;
    });
  }
}
