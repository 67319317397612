// src/app/utils/date-utils.ts

export function formatMessageTime(timestamp: string): string {
  const messageTime = new Date(timestamp);
  const now = new Date();

  if (messageTime.toDateString() === now.toDateString()) {
    return formatTime(messageTime);
  } else {
    return `${formatDate(messageTime)} ${formatTime(messageTime)}`;
  }
}

export function formatDate(date: Date): string {
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero indexed
  const year = date.getUTCFullYear();

  return `${padZero(day)}/${padZero(month)}/${year}`;
}
export function getFormattedDate(date: Date): string {
  // Chuyển đổi thời gian theo múi giờ số 7 (UTC+7)
  const utcDate = date.getTime() + date.getTimezoneOffset() * 60000;
  const timezoneOffset = 7 * 60 * 60000; // UTC+7 in milliseconds
  const localDate = new Date(utcDate + timezoneOffset);

  // Định dạng thời gian thành HH:mm
  const hours = localDate.getHours().toString().padStart(2, '0');
  const minutes = localDate.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}
export function formatTime(date: Date): string {
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  return `${padZero(hours)}:${padZero(minutes)}`;
}

export function padZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}
