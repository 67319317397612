<h1>Thay đổi mật khẩu</h1>

<form class="password-form" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="password-full-width">
        <mat-label>Nhập mật khẩu cũ</mat-label>
        <input type="password" matInput placeholder="OldPassword" value="{{userProfile.OldPassword}}" formControlName="OldPassword">
        <small class="text-danger mx-3 text-end d-block" *ngIf="passwordForm.get('OldPassword')?.invalid 
                        && passwordForm.get('OldPassword')?.touched">
            Required
        </small>
    </mat-form-field>
    <mat-form-field class="password-full-width">
        <mat-label>Nhập mật khẩu mới</mat-label>
        <input type="password" matInput placeholder="NewPassword" value="{{userProfile.NewPassword}}" formControlName="NewPassword">
    </mat-form-field>
    <mat-form-field class="password-full-width">
        <mat-label>Nhập lại mật khẩu mới</mat-label>
        <input type="password" matInput placeholder="ConfirmNewPassword" value="{{userProfile.ConfirmNewPassword}}" formControlName="ConfirmNewPassword">
    </mat-form-field>

    <button class="btn btn-primary mt-3" type="submit" [disabled]="passwordForm.invalid">Submit</button>
</form>