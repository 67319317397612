import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-admin-notification',
  templateUrl: './admin-notification.component.html',
  styleUrls: ['./admin-notification.component.css'],
})
export class AdminNotificationComponent implements OnInit {
  adminNotifications: any[] = [];
  animationPaused: boolean = false;
  constructor(
    private notificationService: NotificationService,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.notificationService.notificationsAdmin$.subscribe((notifications) => {
      this.adminNotifications = notifications;
    });
  }
  windowHeight: number = window.innerHeight;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.windowHeight = window.innerHeight;
  }
  getTopPosition(index: number): number {
    const initialTop = 50;
    const position = initialTop + index * 20;
    const maxHeight = this.windowHeight * 0.8;
    return position % maxHeight;
  }
  pauseAnimation(): void {
    this.animationPaused = true;
    this.notificationService.pauseAnimationSubject.next(true);
  }

  resumeAnimation(): void {
    this.animationPaused = false;
    this.notificationService.pauseAnimationSubject.next(false);
  }
}
