<div
  class="flex flex-col bg-white shadow-md p-4 w-full max-h-[500px] overflow-y-auto"
>
  <h2 class="font-bold text-lg mb-2">Comments</h2>
  <div *ngFor="let comment of comments" class="mb-4 border-b pb-4">
    <div class="flex items-center mb-2">
      <img
        [src]="comment.avatar"
        alt="{{ comment.name }}"
        class="w-10 h-10 rounded-full mr-3"
      />
      <div>
        <div class="flex items-center">
          <h3 class="font-bold text-md mr-2">{{ comment.name }}</h3>
          <span class="text-sm text-gray-500">{{ comment.time }}</span>
        </div>
        <p class="text-sm text-gray-600">@{{ comment.email }}</p>
      </div>
    </div>
    <p class="text-gray-800 mb-2">{{ comment.content }}</p>
    <div class="flex items-center gap-2 text-gray-500 text-sm">
      <button
        (click)="likeComment(comment)"
        class="flex items-center gap-1 hover:text-blue-500"
      >
        <span>&#128077;</span>
        <span>{{ comment.likes }}</span>
      </button>
      <button
        (click)="dislikeComment(comment)"
        class="flex items-center gap-1 hover:text-red-500"
      >
        <span>&#128078;</span>
        <span>{{ comment.dislikes }}</span>
      </button>
      <button (click)="replyToComment(comment)" class="hover:text-gray-800">
        Reply
      </button>
    </div>
  </div>
  <div class="mt-4">
    <input
      type="text"
      placeholder="Add your comment"
      class="w-full p-2 border border-gray-300 rounded"
    />
  </div>
</div>
