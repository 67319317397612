<div
  class="flex justify-between items-center px-4 py-2 bg-white border-b overflow-hidden"
>
  <ng-container *ngFor="let category of categories; let i = index">
    <div
      class="flex flex-col items-center space-y-1 cursor-pointer hover:text-blue-600"
      [ngClass]="{
        'hidden md:flex': i >= visibleItemsCount
      }"
    >
      <div class="text-2xl">
        <i [ngClass]="category.icon"></i>
      </div>
      <span class="text-sm font-medium">{{ category.label }}</span>
    </div>
  </ng-container>
</div>
