import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CartService } from 'src/app/components/cart/cart.service';
import { AccountService } from 'src/app/components/user-profile/account.service';
import { UserLogin } from 'src/app/models/user-login';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-auth-view',
  templateUrl: './auth-view.component.html',
  styleUrls: ['./auth-view.component.css'],
})
export class AuthViewComponent implements OnInit {
  public loginEndpoint: string = '';
  public logoutEndpoint: string = '';
  public userProfile: UserLogin = new UserLogin();
  public searchQuery: string = '';
  public showDropdown = false;

  searchControl = new FormControl('');
  recentSearches: string[] = ['Nuna Car seats', '123'];
  popularSearches: string[] = [
    'DockATot Baby Care',
    'DJI Drone',
    'Good Smile Company Figurines',
    'Xbox Series X',
    'Nuna Car seats',
    'iRobot Roomba',
    'Disney Loungefly',
    'GoPro HERO8',
    'LEGO',
    'Pokemon',
    'Apple Airpods',
    'Air Jordan',
  ];
  isModalOpen = false;
  constructor(
    private configuration: ConfigurationService,
    private accountService: AccountService,
    private localStorage: LocalStoreManager,
    public cartService: CartService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.loginEndpoint = this.configuration.loginEndpoint;
    this.logoutEndpoint = this.configuration.logoutEndpoint;
    this.accountService.getUserLogin().subscribe({
      next: (data) => {
        this.userProfile = data;
        this.localStorage.savePermanentData(data, 'CURRENT_USER');
      },
    });
  }

  onSearchChange() {}
  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }
  loginWithPasswordFlow() {
    this.isModalOpen = false;
    this.httpClient.get('/account/login').subscribe(() => {
      window.location.href = '/';
    });
  }
  hideDropdown() {
    setTimeout(() => (this.showDropdown = false), 200);
  }

  clearHistory() {
    this.recentSearches = [];
  }

  clearLocalStorage() {
    this.localStorage.deleteData('CURRENT_USER');
    window.location.href = this.logoutEndpoint;
  }
}
