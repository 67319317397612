import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostManagementService {
  constructor(private httpClient: HttpClient) {}
  getProductsByUserName(userName: string): Observable<any> {
    return this.httpClient.get<any>(
      `api/Catalog/GetProductsByUserName/${userName}`
    );
  }
  deleteProduct(id: string): Observable<void> {
    return this.httpClient.delete<void>(`api/Catalog/${id}`);
  }
}
