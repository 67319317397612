import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './pipes/truncate.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

@NgModule({
  declarations: [TruncatePipe, TimeAgoPipe],
  imports: [CommonModule],
  exports: [TruncatePipe, TimeAgoPipe],
})
export class SharedModule {}
