<div class="flex flex-col w-full h-full">
  <!-- Video Container -->
  <div class="flex items-center justify-center bg-black relative h-screen">
    <div class="relative w-full h-full">
      <video
        #remoteVideo
        id="remoteVideo"
        autoplay
        class="absolute w-full h-full object-cover"
      ></video>
    </div>
    <div class="absolute top-2 right-2 w-32 h-24 border-2 border-white">
      <video
        #localVideo
        id="localVideo"
        autoplay
        muted
        class="absolute w-full h-full object-cover"
      ></video>
    </div>
    <div
      class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4"
    >
      <button
        (click)="toggleCameraOnOff()"
        class="p-2 bg-gray-400 rounded-full focus:outline-none flex items-center justify-center w-12 h-12"
      >
        <ng-container *ngIf="isCameraOn; else offCamera">
          <svg
            class="w-9 h-9 text-gray-800 dark:text-white rounded-full"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M14 6H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 11-6-2V9l6-2v10Z"
            />
          </svg>
        </ng-container>
        <ng-template #offCamera>
          <img
            class="large-icon w-12 h-12 rounded-full"
            src="assets/icons/turn-off-camer.svg"
            alt="Menu"
          />
        </ng-template>
      </button>
      <button
        (click)="toggleCamera()"
        class="p-2 bg-red-600 rounded-full focus:outline-none flex items-center justify-center w-12 h-12"
      >
        <img
          class="large-icon w-9 h-9 rounded-full"
          src="assets/icons/camera-switch.svg"
          alt="Menu"
        />
      </button>

      <button
        (click)="toggleMicrophone()"
        class="p-2 bg-gray-400 rounded-full focus:outline-none flex items-center justify-center w-12 h-12"
      >
        <ng-container *ngIf="isMicrophoneOn; else offMicro">
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white rounded-full"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M5 8a1 1 0 0 1 1 1v3a4.006 4.006 0 0 0 4 4h4a4.006 4.006 0 0 0 4-4V9a1 1 0 1 1 2 0v3.001A6.006 6.006 0 0 1 14.001 18H13v2h2a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h2v-2H9.999A6.006 6.006 0 0 1 4 12.001V9a1 1 0 0 1 1-1Z"
              clip-rule="evenodd"
            />
            <path
              d="M7 6a4 4 0 0 1 4-4h2a4 4 0 0 1 4 4v5a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4V6Z"
            />
          </svg>
        </ng-container>
        <ng-template #offMicro>
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white rounded-full"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="m15.506 14.097.994.995A3.989 3.989 0 0 0 17.975 12V9.011a.996.996 0 0 1 1.992 0v2.99a5.981 5.981 0 0 1-2.054 4.503l1.762 1.762a.996.996 0 1 1-1.408 1.408L4.325 5.733a.996.996 0 0 1 1.408-1.408L7.04 5.632a3.984 3.984 0 0 1 3.964-3.59h1.992c2.2 0 3.983 1.783 3.983 3.983v4.98a3.975 3.975 0 0 1-1.473 3.092ZM4.033 10.008a.996.996 0 1 1 1.992 0V12a3.99 3.99 0 0 0 3.984 3.984H12c.55 0 .996.446.996.996v2.988h1.992a.996.996 0 0 1 0 1.992H9.012a.996.996 0 0 1 0-1.992h1.992v-1.992h-.997a5.981 5.981 0 0 1-5.974-5.974v-1.993Z"
            />
          </svg>
        </ng-template>
      </button>
      <button
        (click)="shareScreen()"
        class="p-2 bg-green-500 rounded-full focus:outline-none flex items-center justify-center w-12 h-12"
      >
        <img
          class="large-icon w-9 h-9 rounded-full"
          src="assets/icons/desktop-screen-share-icon.svg"
          alt="Menu"
        />
      </button>
      <button
        (click)="call()"
        class="p-2 bg-blue-500 rounded-full focus:outline-none flex items-center justify-center w-12 h-12"
      >
        Gọi
      </button>
    </div>
  </div>
  <div
    *ngIf="webRTCSignalrChatService.incomingCall"
    class="absolute inset-0 flex items-center justify-center bg-opacity-75"
  >
    <div class="bg-white p-4 rounded shadow-lg">
      <p class="text-lg font-bold">{{ senderNameCalling }} đang gọi</p>
      <div class="flex space-x-4 mt-4 items-center justify-center">
        <button
          (click)="handleIncomingCall()"
          class="bg-blue-500 text-white rounded"
        >
          Chấp nhận
        </button>
        <button (click)="rejectCall()" class="bg-red-500 text-white rounded">
          Từ chối
        </button>
      </div>
    </div>
  </div>
</div>
