import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from 'src/app/models/product/product';
import { PagedResult } from 'src/app/models/paged-result.model';
import { GetByIdProduct } from 'src/app/models/product/product.getbyid';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpClient: HttpClient) {}
  getProducts(
    pageNumber: number,
    pageSize: number
  ): Observable<PagedResult<Product>> {
    return this.httpClient.get<PagedResult<Product>>(
      `api/Catalog?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }
  getProduct(id: string): Observable<GetByIdProduct> {
    return this.httpClient.get<GetByIdProduct>(`/api/Catalog/${id}`);
  }

  getProductsByPriceRange(
    minPrice: number,
    maxPrice: number
  ): Observable<Product[]> {
    const params = new HttpParams()
      .set('minPrice', minPrice.toString())
      .set('maxPrice', maxPrice.toString());

    return this.httpClient.get<Product[]>(
      `/api/Catalog/GetProductsByPriceRange`,
      {
        params,
      }
    );
  }
  uploadProduct(formData: FormData): Observable<Product> {
    return this.httpClient.post<Product>('/api/Catalog', formData);
  }
}
