import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Payment } from 'src/app/models/payment/payment';
import { UserLogin } from 'src/app/models/user-login';
import { UserProfile } from 'src/app/models/user.profile';
import { UserPassword } from 'src/app/models/user/user.password';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private httpClient: HttpClient) {}
  getUserProfile(): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>('/api/UserInfo');
  }
  updateUserProfile(userProfile: UserProfile): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.put<void>(
      '/api/UserInfo',
      JSON.stringify(userProfile),
      { headers }
    );
  }
  getUserLogin(): Observable<UserLogin> {
    return this.httpClient.get<UserLogin>(`/api/User`);
  }
  changeAvatar(dataUrl: string): Observable<void> {
    return this.httpClient.put<void>(
      '/api/UserInfo/changeAvatar',
      JSON.stringify(dataUrl)
    );
  }
  changePassword(data: UserPassword): Observable<void> {
    return this.httpClient.put<void>('/api/UserInfo/changePassword', data);
  }
  AddToken(userName: string, token: number): Observable<void> {
    const body = {
      userName: userName,
      token: token,
    };
    return this.httpClient.post<void>('/api/UserInfo/AddToken', body);
  }
}
