import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { UserLogin } from 'src/app/models/user-login';
import { DBkeys } from 'src/app/services/db-keys';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private hubConnection!: signalR.HubConnection;
  private notificationsSubject: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  public notifications$: Observable<any[]> =
    this.notificationsSubject.asObservable();

  private notificationsAdminSubject: BehaviorSubject<any[]> =
    new BehaviorSubject<any[]>([]);
  public notificationsAdmin$: Observable<any[]> =
    this.notificationsAdminSubject.asObservable();

  public pauseAnimationSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public pauseAnimation$: Observable<boolean> =
    this.pauseAnimationSubject.asObservable();

  constructor(
    private authService: AuthService,
    private localStorage: LocalStoreManager
  ) {
    if (this.authService.isLoggedIn) {
      const avatar = this.localStorage.getDataObject<UserLogin>(
        DBkeys.CURRENT_USER
      )?.avatarUrl;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`/chatHub?Avatar=${avatar}`)
        .configureLogging(signalR.LogLevel.None)
        .build();
      this.hubConnection.on('ReceiveMessage', (user, messages) => {
        this.AdminNotification(messages.Message);
      });
      this.hubConnection.on('ReceiveMessageUser', (user, messages) => {
        this.AdminNotification(messages.Message);
      });
    } else {
      console.log(
        'User is not logged in. NotificationService will not be initialized.'
      );
    }
  }

  addNotification(
    conversationId: string,
    sender: string,
    message: string
  ): void {
    const currentNotifications = this.notificationsSubject.value;
    const newNotification = {
      conversationId: conversationId,
      sender: sender,
      message: message,
      read: false,
    };
    this.notificationsSubject.next([...currentNotifications, newNotification]);
  }

  AdminSendPublicMessage(user: string, message: string): Promise<void> {
    return this.hubConnection
      .invoke('SendMessage', user, { Message: message })
      .catch((err) => {
        throw err;
      });
  }
  AdminSendMessage(receiverName: string, message: string): Promise<void> {
    return this.hubConnection
      .invoke('SendMessageToUser', receiverName, { Message: message })
      .catch((err) => {
        throw err;
      });
  }
  AdminNotification(message: string): void {
    const currentAdminNotifications = this.notificationsAdminSubject.value;
    const newAdminNotification = {
      sender: 'Admin',
      message: message,
      read: false,
    };
    this.notificationsAdminSubject.next([
      ...currentAdminNotifications,
      newAdminNotification,
    ]);
  }
}
