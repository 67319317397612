import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { UserLogin } from 'src/app/models/user-login';
import { DBkeys } from 'src/app/services/db-keys';
import { WebRTCSignalrChatService } from './webrtc-signalr-chat.service';
import { MessageSend } from 'src/app/models/messages';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { formatMessageTime, getFormattedDate } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';

interface ChatMessage {
  time: string;
  text: string;
  isCurrentUser: boolean;
  avatar: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, AfterViewInit {
  @ViewChild('chatMessages') private messagesContainer!: ElementRef;
  conversations: string[] = [];
  currentChat = {
    messages: [] as ChatMessage[],
  };
  currentUserId: string = '';
  currentUserName: string = '';
  currentUserAvatar: string = '';

  openVideoCall: boolean = false;
  selectedUserIdInteractive: string = '';
  selectedUserAvatar: string = '';
  selectedUserName: string = '';

  usersInteracted: any[] = [];
  newMessage = '';
  selectedFile: File | null = null;
  imagePreview: string | null = null;
  loadingMoreMessages = false;
  totalPages: number = 0;
  page = 1;
  constructor(
    private webRTCSignalrChatService: WebRTCSignalrChatService,
    private localStorage: LocalStoreManager,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.scrollToBottom(), 0);
  }

  async ngOnInit() {
    this.loadUsersInteracted();
    this.subscribeToMessages();
    const currentUser = this.localStorage.getDataObject<UserLogin>(
      DBkeys.CURRENT_USER
    );
    this.currentUserId = currentUser?.userId ?? '';
    this.currentUserName = currentUser?.userName ?? '';
    this.currentUserAvatar = currentUser?.avatarUrl ?? '';

    this.route.queryParams.subscribe((params) => {
      if (params['userId']) {
        this.selectedUserIdInteractive = params['userId'];
        const state = history.state;
        if (state) {
          this.selectedUserAvatar = state['userAvatar'] || '';
          this.selectedUserName = state['userName'] || '';
        }
        if (this.selectedUserIdInteractive) {
          this.selectUserInteractive(
            this.selectedUserIdInteractive,
            this.selectedUserName,
            this.selectedUserAvatar
          );
        }
      }
    });
  }

  private loadUsersInteracted() {
    this.webRTCSignalrChatService.getUsersInteracted().subscribe((data) => {
      this.usersInteracted = data.map((usersInteracted) => ({
        userId: usersInteracted.id,
        userName: usersInteracted.userName,
        avatarUrl: usersInteracted.avatarUrl,
        getTimeSinceLastActive: usersInteracted.getTimeSinceLastActive,
      }));
      this.route.queryParams.subscribe((params) => {
        if (!params['userId']) {
          this.selectedUserIdInteractive = this.usersInteracted[0].userId;
          this.router.navigate(['/chat'], {
            queryParams: { userId: this.selectedUserIdInteractive },
            state: {
              userId: this.usersInteracted[0].userId,
              userAvatar: this.usersInteracted[0].avatarUrl,
              userName: this.usersInteracted[0].userName,
            },
          });
        }
      });
      this.scrollToBottom();
    });
  }

  private subscribeToMessages(): void {
    this.webRTCSignalrChatService.messagesToChatSubject.subscribe((message) => {
      if (message && message.senderId == this.selectedUserIdInteractive) {
        this.currentChat.messages.push({
          time: message.timestamp,
          text: message.message,
          isCurrentUser: message.senderName === this.currentUserName,
          avatar: message.avatalSender,
        });
        setTimeout(() => this.scrollToBottom(), 0);
        this.cdr.markForCheck();
      }
    });
  }

  openVideoChat(userId: string, userName: string): void {
    sessionStorage.setItem('receiverIdToCall', userId);
    sessionStorage.setItem('receiverNameToCall', userName);
    this.appComponent.isVideoChatVisible = true;
  }

  selectUserInteractive(
    userId: string,
    userName: string,
    avatarUrl: string
  ): void {
    this.loadMessages(this.currentUserId, userId, 1, 20);
    this.selectedUserIdInteractive = userId;
    this.page = 1;
    this.selectedUserAvatar = avatarUrl;
    this.selectedUserName = userName;
    this.router.navigate(['/chat'], {
      queryParams: { userId: userId },
      state: { userId: userId, userAvatar: avatarUrl, userName: userName },
    });
    this.scrollToBottom();
  }

  isImage(url: string): boolean {
    return (
      /\.(jpg|jpeg|png|gif|svg)$/i.test(url) &&
      url.startsWith('https://identity-hka.click/')
    );
  }

  isFile(url: string): boolean {
    return url.startsWith('https://identity-hka.click/wwwroot/user/uploads/');
  }

  isVideo(url: string): boolean {
    return (
      /\.(mp4|webm|ogg)$/i.test(url) &&
      url.startsWith('https://identity-hka.click/')
    );
  }

  isMediaContent(url: string): boolean {
    return this.isImage(url) || this.isVideo(url) || this.isAudio(url);
  }

  isDocument(text: string): boolean {
    return /\.(doc|docx|pdf|txt|xls|xlsx)$/i.test(text);
  }

  isZip(text: string): boolean {
    return /\.(zip)$/i.test(text);
  }
  isAudio(url: string): boolean {
    return /\.(mp3|wav|ogg)$/i.test(url);
  }
  splitEmail(email: string): string {
    if (email.includes('@')) return email.split('@')[0];
    return email;
  }
  scrollToBottom(): void {
    if (this.messagesContainer) {
      setTimeout(() => {
        this.messagesContainer.nativeElement.scrollTop =
          this.messagesContainer.nativeElement.scrollHeight;
      }, 0);
    }
  }

  onScroll(): void {
    const container = this.messagesContainer?.nativeElement;
    if (container.scrollTop === 0) {
      container.scrollTop = 10;
      this.loadMoreMessages(this.selectedUserIdInteractive!);
    }
  }
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.sendMessage();
    }
  }
  sendMessage(): void {
    this.uploadFile();
    if (this.newMessage.trim()) {
      this.webRTCSignalrChatService
        .sendMessage(
          this.selectedUserIdInteractive,
          new MessageSend(this.newMessage)
        )
        .then(() => {
          setTimeout(() => this.scrollToBottom(), 0);
          this.currentChat.messages.push({
            time: getFormattedDate(new Date()),
            text: this.newMessage,
            isCurrentUser: this.currentUserName === this.currentUserName,
            avatar: this.currentUserAvatar,
          });
          this.newMessage = '';
          this.cdr.markForCheck();
        })
        .catch((err) => console.error('Error while sending message: ' + err));
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result as string;
        this.cdr.markForCheck();
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  removeImage(): void {
    this.selectedFile = null;
    this.imagePreview = null;
    this.cdr.markForCheck();
  }

  uploadFile(): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);

      this.webRTCSignalrChatService.uploadFile(formData).subscribe(
        (response: any) => {
          if (response && response.fileUrl) {
            const fileUrl = response.fileUrl;
            this.webRTCSignalrChatService
              .sendMessage(
                this.selectedUserIdInteractive,
                new MessageSend(fileUrl)
              )
              .then(() => {
                setTimeout(() => this.scrollToBottom(), 0);
                this.currentChat.messages.push({
                  time: getFormattedDate(new Date()),
                  text: fileUrl,
                  isCurrentUser: this.currentUserName === this.currentUserName,
                  avatar: this.currentUserAvatar,
                });
                this.imagePreview = null;
                this.cdr.markForCheck();
              })
              .catch((err) =>
                console.error('Error while sending message: ' + err)
              );
            this.selectedFile = null;
          }
        },
        (error) => {
          Swal.fire({
            title: 'Lỗi!',
            text: 'Định dạng file không hợp lệ',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      );
    }
  }

  loadMessages(
    firstUserId: string,
    secondUserId: string,
    page: number,
    limit: number
  ): void {
    this.loadingMoreMessages = true;
    this.webRTCSignalrChatService
      .loadMessages(firstUserId, secondUserId, page, limit)
      .subscribe((response) => {
        const data = response.data;
        const newMessages = data.map((message: any) => ({
          time: message.timestamp,
          text: message.message,
          isCurrentUser: message.sender.id === this.currentUserId,
          avatar: message.sender.avatarUrl,
        }));
        this.totalPages = response.pagination.totalPage;

        if (page === 1) {
          this.currentChat.messages = newMessages;
        } else {
          this.currentChat.messages = [
            ...newMessages,
            ...this.currentChat.messages,
          ];
        }
        this.loadingMoreMessages = false;
        this.cdr.markForCheck();
      });
  }

  loadMoreMessages(secondUserId: string): void {
    if (this.page < this.totalPages || this.totalPages === 0) {
      this.page++;
      this.loadingMoreMessages = false;
      this.loadMessages(this.currentUserId, secondUserId, this.page, 20);
    }
  }
}
