import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { RouterModule } from '@angular/router';
import { UploadProductComponent } from './upload-product/upload-product.component';
import { MatListModule } from '@angular/material/list';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { ProductListComponent } from './product-list/product-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CartModule } from '../cart/cart.module';
import { CommentSectionComponent } from '../comment-section/comment-section.component';
@NgModule({
  providers: [MatDialog],
  declarations: [
    ProductDetailComponent,
    UploadProductComponent,
    ProductListComponent,
    CommentSectionComponent,
  ],
  imports: [
    CartModule,
    SharedModule,
    CommonModule,
    MatPaginatorModule,
    MatGridListModule,
    MatButtonModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    RouterModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    ProductListComponent,
    ProductDetailComponent,
    CommentSectionComponent,
  ],
})
export class ProductModule {}
