<div *ngIf="loading" class="loading-container">
  <mat-spinner></mat-spinner>
</div>
<div class="container">
  <div *ngIf="!loading" class="mt-4">
    <h2 class="text-3xl font-semibold">Quản lý tin</h2>
    <div class="user-info">
      <div class="user-details">
        <img
          *ngIf="userProfile.profileImageUrl; else noImage"
          [src]="userProfile.profileImageUrl"
          alt="User Avatar"
        />
        <div>
          <p>{{ userName }}</p>
          <button mat-raised-button color="primary" (click)="createStore()">
            + Tạo cửa hàng
          </button>
        </div>
      </div>
      <div class="pro-info">
        <div class="balance-info">
          <span>Token hiện có: </span>
          <span
            >{{ userProfile.token }}
            <button
              routerLink="/user/upgrade-account"
              mat-flat-button
              (click)="addBalance()"
            >
              +
            </button></span
          >
        </div>
      </div>
    </div>
    <mat-tab-group [(selectedIndex)]="selectedIndex">
      <mat-tab label="ĐANG HIỂN THỊ ({{ displayedPosts.length }})">
        <div *ngIf="displayedPosts.length === 0" class="no-posts-container">
          <img src="assets/images/promo-image.png" alt="No Posts" />
          <p>Không tìm thấy tin đăng</p>
          <button
            routerLink="/upload-product"
            mat-stroked-button
            (click)="createPost()"
          >
            Đăng tin
          </button>
        </div>
        <div *ngIf="displayedPosts.length > 0">
          <mat-grid-list
            cols="1"
            rowHeight="220px"
            gutterSize="10px"
            style="margin-top: 10px"
          >
            <mat-grid-tile *ngFor="let post of displayedPosts">
              <mat-card class="post-card" style="margin: 10px">
                <mat-card-header>
                  <div mat-card-avatar>
                    <img
                      *ngIf="post.filesPathUrl?.length > 0; else noImage"
                      [src]="post.filesPathUrl[0]"
                      alt=""
                      class="thumbnail"
                    />
                  </div>
                  <mat-card-title>{{ post.title }}</mat-card-title>
                  <mat-card-subtitle>{{ post.category }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <p><strong>Price:</strong> {{ post.price | currency }}</p>
                  <p><strong>State:</strong> {{ post.state }}</p>
                  <p><strong>Date:</strong> {{ post.date }}</p>
                </mat-card-content>
                <mat-card-actions>
                  <button
                    (click)="deleteProduct(post.id)"
                    mat-raised-button
                    color="accent"
                  >
                    Xóa bài đăng
                  </button>
                  <button
                    style="margin-left: 20px"
                    mat-raised-button
                    color="primary"
                  >
                    Chỉnh sửa bài đăng
                  </button>
                </mat-card-actions>
              </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </mat-tab>
      <mat-tab label="HẾT HẠN ({{ expiredPosts.length }})">
        <img src="assets/images/promo-image.png" alt="No Posts" />
      </mat-tab>
      <mat-tab label="BỊ TỪ CHỐI ({{ rejectedPosts.length }})">
        <div *ngIf="rejectedPosts.length === 0" class="no-posts-container">
          <img src="assets/images/promo-image.png" alt="No Posts" />
          <p>Không tìm thấy tin bị từ chối</p>
        </div>
        <div *ngIf="rejectedPosts.length > 0">
          <mat-grid-list
            cols="1"
            rowHeight="220px"
            gutterSize="10px"
            style="margin-top: 10px"
          >
            <mat-grid-tile *ngFor="let post of rejectedPosts">
              <mat-card class="post-card">
                <mat-card-header>
                  <div mat-card-avatar>
                    <img
                      *ngIf="post.filesPathUrl?.length > 0; else noImage"
                      [src]="post.filesPathUrl[0]"
                      alt="Thumbnail"
                      class="thumbnail"
                    />
                    <ng-template #noImage>
                      <img
                        src="assets/images/default-thumbnail.png"
                        alt="No Image"
                        class="thumbnail"
                      />
                    </ng-template>
                  </div>
                  <mat-card-title>{{ post.title }}</mat-card-title>
                  <mat-card-subtitle>{{ post.category }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <p><strong>Price:</strong> {{ post.price | currency }}</p>
                  <p><strong>State:</strong> {{ post.state }}</p>
                  <p><strong>Date:</strong> {{ post.date }}</p>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button>View</button>
                  <button mat-button>Edit</button>
                </mat-card-actions>
              </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </mat-tab>
      <mat-tab label="CẦN THANH TOÁN ({{ paymentPendingPosts.length }})">
      </mat-tab>
      <mat-tab label="TIN NHÁP ({{ draftPosts.length }})"> </mat-tab>
      <mat-tab label="CHỜ DUYỆT ({{ pendingPosts.length }})"> </mat-tab>
      <mat-tab label="ĐÃ ẨN ({{ hiddenPosts.length }})"> </mat-tab>
    </mat-tab-group>
  </div>
</div>
<ng-template #noImage>
  <img src="assets/images/no-image.png" alt="No Image" class="thumbnail" />
</ng-template>
