import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-keys';
import { LocalStoreManager } from './local-store-manager.service';
import { Subject } from 'rxjs';
import { UserLogin } from '../models/user-login';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loginRedirectUrl: string | null = null;
  public logoutRedirectUrl: string | null = null;
  private previousIsLoggedInCheck = false;
  private loginStatus = new Subject<boolean>();

  constructor(
    private localStorage: LocalStoreManager,
    private httpClient: HttpClient
  ) {}

  private reevaluateLoginStatus(currentUser?: User | null) {
    const user =
      currentUser ?? this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    const isLoggedIn = user != null;
    if (this.previousIsLoggedInCheck !== isLoggedIn) {
      setTimeout(() => {
        this.loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }
  get currentUser(): User | null {
    const user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    this.reevaluateLoginStatus(user);

    return user;
  }
  get isLoggedIn(): boolean {
    return (
      this.localStorage.getDataObject<UserLogin>(DBkeys.CURRENT_USER)
        ?.isAuthenticated === true
    );
  }
  oicdPasswordFlow() {
    this.httpClient.get('/account/login').subscribe(() => {
      window.location.href = '/';
    });
  }
  openLoginPopup() {
    const width = 600;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    console.log(left);
    console.log(top);
    const popup = window.open(
      'Account/LoginWithOpenIdConnect',
      'Login',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    const checkLoginStatus = setInterval(() => {
      if (this.isLoggedIn) {
        if (popup && !popup.closed) {
          popup.close();
        }
        window.location.reload();
        clearInterval(checkLoginStatus);
      }
    }, 1000);
  }
}
