<div class="cart-panel" [class.show]="cartService.isCartVisible | async">
    <div class="cart-header">
        <span>Cart</span>
        <button (click)="closeCart()">✖</button>
    </div>
    <div class="cart-content">
        <div *ngFor="let item of cartService.cartItems | async" class="cart-item">
            <img [src]="item.image" alt="{{ item.name }}" class="cart-item-image">
            <div class="cart-item-details">
                <strong>{{ item.name }}</strong>
                <p>
                    <span class="current-price">{{ item.price | currency:"VND" }}</span>
                    <span class="original-price">{{ item.originalPrice | currency:"VND" }}</span>
                </p>
            </div>
            <button (click)="removeItem(item)" class="remove-item">
                <img src="assets/icons/trash.svg" alt="Delete" class="delete-icon">
            </button>
        </div>
    </div>
    <div class="cart-footer">
        <span>Subtotal ({{ (cartService.cartItems | async)?.length }} items) {{ cartService.cartTotal | async | currency:"VND" }}</span>
        <button class="view-cart-button">View cart</button>
    </div>
</div>