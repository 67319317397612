import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  BootstrapTabDirective,
  EventArg,
} from 'src/app/directives/bootstrap-tab.directive';
import { fadeInOut } from 'src/app/services/animations';
import { AccountService } from '../user-profile/account.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  animations: [fadeInOut],
})
export class SettingsComponent implements OnDestroy {
  isProfileActivated = true;
  isPreferencesActivated = false;
  isUsersActivated = false;
  isRolesActivated = false;
  fragmentSubscription: Subscription | undefined;

  readonly profileTab = 'profile';
  readonly preferencesTab = 'preferences';
  readonly usersTab = 'users';
  readonly rolesTab = 'roles';
  @ViewChild('tab', { static: true })
  tab!: BootstrapTabDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService
  ) {}

  ngOnDestroy() {
    this.fragmentSubscription?.unsubscribe();
  }

  isFragmentEquals(fragment1: string | null, fragment2: string | null) {
    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() === fragment2.toLowerCase();
  }
  onShowTab(event: EventArg) {
    const activeTab = (event.target as HTMLAnchorElement).hash
      .split('#', 2)
      .pop();

    this.isProfileActivated = activeTab === this.profileTab;
    this.isPreferencesActivated = activeTab === this.preferencesTab;
    this.isUsersActivated = activeTab === this.usersTab;
    this.isRolesActivated = activeTab === this.rolesTab;

    this.router.navigate([], { fragment: activeTab });
  }
}
