// src/app/upgrade-account/upgrade-account.component.ts
import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { PaymentService } from 'src/app/services/payment.service';
import { Payment } from 'src/app/models/payment/payment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upgrade-account',
  templateUrl: './upgrade-account.component.html',
  styleUrls: ['./upgrade-account.component.css'],
})
export class UpgradeAccountComponent implements OnInit {
  packages: any[] = [];
  selectedPackage: any = null;
  constructor(
    private viewportScroller: ViewportScroller,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.packages = [
      {
        id: 'basic1',
        title: 'Gói Đăng Tin',
        subtitle: 'Cơ bản',
        description:
          'Dành cho mô hình kinh doanh nhỏ, người bắt đầu kinh doanh.',
        originalPrice: '120.000',
        discountedPrice: '100.000',
        discountPercentage: 16,
        postLimit: 25,
      },
      {
        id: 'pro1',
        title: 'Gói Đăng Tin + Đẩy tin',
        subtitle: 'Chuyên nghiệp',
        description: 'Dành cho người bán chuyên nghiệp, người kinh doanh.',
        originalPrice: '240.000',
        discountedPrice: '200.000',
        discountPercentage: 27,
        postLimit: 50,
      },
      {
        id: 'vip1',
        title: 'Gói VIP',
        subtitle: 'VIP',
        description:
          'Dành cho người bán chuyên nghiệp có quy mô lớn và quản lý hiệu suất bán hàng',
        originalPrice: '350.000',
        discountedPrice: '300.000',
        discountPercentage: 20,
        postLimit: 80,
      },
    ];
  }

  scrollToPackages() {
    this.viewportScroller.scrollToAnchor('packages');
  }

  onBuyNow(selectedPackage: any) {
    this.router.navigate(['/checkout/subscription'], {
      state: { selectedPackage },
    });
  }
}
