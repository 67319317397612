import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import Swal from 'sweetalert2';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  if (authService.isLoggedIn) {
    return true;
  }
  //window.location.href = 'account/login';
  Swal.fire({
    title: 'You need to log in',
    text: 'You need to log in to access this page.',
    icon: 'warning',
    showDenyButton: true,
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#f0ad4e',
    confirmButtonText: 'Password Flow',
    denyButtonText: 'Client Credentials Flow',
  }).then((result) => {
    if (result.isConfirmed) {
      authService.oicdPasswordFlow();
    } else if (result.isDenied) {
      authService.openLoginPopup();
    }
  });
  return false;
};
