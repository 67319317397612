<div *ngIf="loading" class="loading-container">
    <mat-spinner></mat-spinner>
</div>
<div class="container">
    <div *ngIf="!loading">
        <div class="checkout-container">
            <p>Dịch vụ</p>
            <div class="selected-package">
                <p>{{selectedPackage?.title}} - {{selectedPackage?.subtitle}}</p>
                <p>Thời gian: 30 ngày</p>
                <p class="price">{{selectedPackage?.discountedPrice}} đ</p>
            </div>
            <hr>
            <div class="total-amount">
                <p>Tổng tiền (Bao gồm VAT):</p>
                <span class="price">{{selectedPackage?.discountedPrice}} đ</span>
            </div>
            <p>Chọn hình thức thanh toán:</p>
            <mat-radio-group [(ngModel)]="selectedPaymentMethod" class="payment-methods">
                <div *ngFor="let item of paymentMethods" class="payment-method-item">
                    <img [src]="item.image" alt="{{item.description}} icon" class="payment-icon">
                    <span class="payment-description">{{item.description}}</span>
                    <mat-radio-button class="payment-option" [value]="item.id"></mat-radio-button>
                </div>
            </mat-radio-group>
            <form (ngSubmit)="onSubmit()" style="display: flex;">
                <button style="margin-left: auto; margin-right: 20px;" mat-raised-button color="primary"
                    [disabled]="isPaymentButtonDisabled()">
                    Thanh toán ngay
                </button>
            </form>
        </div>
    </div>
    
</div>