import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { WebRTCSignalrChatService } from './components/chat/webrtc-signalr-chat.service';
import Swal from 'sweetalert2';
import { VideoChatComponent } from './components/chat/video-chat/video-chat.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title: string = 'Second_Life';
  @ViewChild('videoChatContainer', { static: false })
  videoChatContainer!: ElementRef;
  isDragging = false;
  initialX = 0;
  initialY = 0;
  xOffset = 0;
  yOffset = 0;
  isVideoChatVisible: boolean = false;

  constructor(
    private videoChatComponent: VideoChatComponent,
    private webRTCSignalrChatService: WebRTCSignalrChatService
  ) {}

  ngOnInit(): void {
    this.webRTCSignalrChatService.messagesToCall$.subscribe((message) => {
      if (message?.type === 'offer') {
        sessionStorage.setItem('SenderIdCalling', message.data.SenderId);
        sessionStorage.setItem('SenderNameCalling', message.data.SenderName);
        this.isVideoChatVisible = true;
        this.videoChatComponent.handleIncomingCall();
      }
    });
  }
  ngAfterViewInit(): void {}

  toggleVideoChatVisibility(): void {
    this.isVideoChatVisible = !this.isVideoChatVisible;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this.isDragging && this.videoChatContainer) {
      const x = event.clientX - this.initialX;
      const y = event.clientY - this.initialY;

      this.xOffset = x;
      this.yOffset = y;

      this.setTranslate(x, y);
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(): void {
    this.isDragging = false;
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.id === 'dragHandle') {
      this.isDragging = true;
      this.initialX = event.clientX - this.xOffset;
      this.initialY = event.clientY - this.yOffset;
    }
  }

  private setTranslate(xPos: number, yPos: number): void {
    if (this.videoChatContainer && this.videoChatContainer.nativeElement) {
      this.videoChatContainer.nativeElement.style.transform = `translate(${xPos}px, ${yPos}px)`;
    }
  }
}
