<div class="mt-[68px]">
  <app-auth-view></app-auth-view>
  <router-outlet></router-outlet>
  <app-cart></app-cart>
  <app-footer></app-footer>

  <div
    *ngIf="isVideoChatVisible"
    class="fixed bottom-20 right-4 max-sm:w-[90%] w-[560px] h-[315px] bg-white shadow-lg rounded-lg"
    id="videoChatContainer"
    #videoChatContainer
  >
    <div
      class="cursor-move bg-gray-800 text-white p-2 rounded-t-lg flex justify-between items-center"
      id="dragHandle"
    >
      <span>Video Chat</span>
      <button
        (click)="toggleVideoChatVisibility()"
        class="p-1 bg-gray-500 text-white rounded-full focus:outline-none ml-2"
      >
        X
      </button>
    </div>
    <app-video-chat></app-video-chat>
  </div>
</div>
