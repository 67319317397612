<div class="flex justify-center max-w-6xl mx-auto h-[100vh] max-h-[100vh]">
  <div
    class="h-[85vh] w-[330px] bg-[#f3e9e9] border-r border-[#b44c4c] rounded-l-lg box-border m-0 md:w-[250px] max-sm:max-w-[60px] sm:border-b sm:border-[#8f4f4f]"
  >
    <div class="flex items-center max-sm:py-5 max-sm:px-2 md:p-4">
      <h4 class="text-center">Users</h4>
    </div>
    <div *ngIf="usersInteracted">
      <div
        *ngFor="let user of usersInteracted"
        [class.selected]="user.userId == selectedUserIdInteractive"
        (click)="
          selectUserInteractive(user.userId, user.userName, user.avatarUrl)
        "
        class="flex flex-col sm:flex-row items-center sm:items-start sm:justify-start h-[70px] w-full p-2 cursor-pointer hover:bg-red-300 text-center sm:text-left"
      >
        <img
          [src]="user.avatarUrl"
          class="w-[50px] h-[50px] rounded-full mb-2 sm:mb-0"
        />
        <div class="flex-grow max-md:hidden sm:block">
          <p class="text-gray-800 font-medium text-left ml-4">
            {{ splitEmail(user.userName) }}
          </p>
          <p class="text-gray-800 text-sm text-end">dev</p>
          <p class="text-gray-800 text-sm text-end">
            {{ user.getTimeSinceLastActive }}
          </p>
        </div>
      </div>
    </div>
    <ng-template #userListContainer>
      <div class="p-2">
        <p class="text-center">Không có tin nhắn nào</p>
        <p class="text-center">Tin nhắn mới sẽ hiển thị tại đây</p>
      </div>
    </ng-template>
  </div>

  <div
    class="h-[85vh] flex flex-col max-w-[800px] w-full border border-gray-300 rounded-lg flex-grow m-0 md:w-[500px] md:rounded-none sm:w-[400px] sm:rounded-none"
  >
    <div
      class="py-2 md:px-5 bg-gray-100 flex items-center justify-between border-b border-gray-300"
    >
      <div class="flex items-center">
        <img
          [src]="selectedUserAvatar"
          alt="avatar"
          class="w-[50px] h-[50px] rounded-full mr-4"
        />
        <h3 class="text-lg font-semibold">
          {{ selectedUserName }}
        </h3>
      </div>
      <svg
        (click)="openVideoChat(selectedUserIdInteractive, selectedUserName)"
        class="w-9 h-9 text-blue-600 cursor-pointer mr-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fill-rule="evenodd"
          d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
          clip-rule="evenodd"
        />
      </svg>
    </div>

    <div
      class="flex-grow md:p-5 max-md:p-1 overflow-y-auto flex flex-col relative"
      #chatMessages
      (scroll)="onScroll()"
    >
      <div *ngIf="loadingMoreMessages" class="justify-center flex items-center">
        <mat-spinner [diameter]="50" class="mt-2"></mat-spinner>
      </div>
      <div
        *ngFor="let message of currentChat.messages"
        class="flex mb-4 message-container"
        [ngClass]="{
          'justify-end': message.isCurrentUser,
          'justify-start': !message.isCurrentUser
        }"
      >
        <img
          *ngIf="!message.isCurrentUser"
          [src]="message.avatar"
          alt="avatar"
          class="w-[40px] h-[40px] rounded-full mr-2.5"
        />
        <div
          [ngClass]="{
            'bg-[#e8fad9] text-right':
              message.isCurrentUser && !isMediaContent(message.text),
            'bg-[#71d2df] text-left':
              !message.isCurrentUser && !isMediaContent(message.text),
            'p-2.5': !isMediaContent(message.text)
          }"
          class="max-w-[70%] rounded-lg"
        >
          <ng-container *ngIf="isImage(message.text); else notImage">
            <img
              loading="lazy"
              [src]="message.text"
              alt="Image"
              class="rounded max-w-full max-h-64 object-contain cursor-pointer"
            />
          </ng-container>
          <ng-template #notImage>
            <ng-container *ngIf="isVideo(message.text); else notVideo">
              <video
                [src]="message.text"
                controls
                [volume]="0.4"
                class="rounded max-w-full max-h-64 object-contain cursor-pointer"
              ></video>
            </ng-container>
            <ng-template #notVideo>
              <ng-container *ngIf="isAudio(message.text); else notAudio">
                <audio
                  [src]="message.text"
                  controls
                  class="w-[300px] max-w-full object-contain cursor-pointer"
                ></audio>
              </ng-container>
              <ng-template #notAudio>
                <ng-container
                  *ngIf="
                    isDocument(message.text) || isZip(message.text);
                    else anotherFile
                  "
                >
                  <a
                    [href]="message.text"
                    download
                    class="flex items-center cursor-pointer text-blue-600"
                  >
                    <span>{{ message.text | truncate : 20 }}</span>
                    <img
                      src="assets/icons/file-earmark-text.svg"
                      alt="File Icon"
                      class="w-10 ml-2"
                    />
                  </a>
                </ng-container>
                <ng-template #anotherFile>
                  <a
                    [href]="message.text"
                    download
                    class="flex cursor-pointer text-blue-600"
                    *ngIf="isFile(message.text); else notMedia"
                  >
                    <span>{{ message.text | truncate : 20 }}</span>

                    <img
                      src="assets/icons/file-earmark-text.svg"
                      alt="File Icon"
                      class="w-10 ml-2"
                    />
                  </a>
                </ng-template>
                <ng-template #notMedia>
                  <p>{{ message.text }}</p>
                </ng-template>
              </ng-template>
            </ng-template>
          </ng-template>
          <span class="text-gray-600 text-xs font-bold">
            {{ message.time }}
          </span>
        </div>

        <img
          *ngIf="message.isCurrentUser"
          [src]="message.avatar"
          alt="avatar"
          class="w-[40px] h-[40px] rounded-full ml-2.5"
        />
      </div>
    </div>

    <div
      class="flex pl-3 py-2 bg-gray-100 border-t border-gray-300 items-center"
    >
      <input
        id="file-input"
        type="file"
        class="hidden"
        (change)="onFileSelected($event)"
      />
      <label for="file-input">
        <img
          class="w-10 mr-5 cursor-pointer"
          src="assets/icons/card-image.svg"
        />
      </label>
      <div *ngIf="imagePreview" class="relative inline-block">
        <img [src]="imagePreview" alt="file" class="w-[50px] mr-5 block" />
        <button
          class="absolute top-0 right-0 bg-red-500 text-white border-none rounded-full w-5 h-5 flex items-center justify-center cursor-pointer text-lg leading-none"
          (click)="removeImage()"
        >
          X
        </button>
      </div>

      <input
        type="text"
        placeholder="Enter message..."
        (keydown)="onKeyDown($event)"
        [(ngModel)]="newMessage"
        class="flex-grow p-2.5 border border-gray-300 rounded mr-2.5 max-sm:max-w-44"
      />
      <button
        (click)="sendMessage()"
        class="p-2.5 mr-2 bg-blue-500 text-white border-none rounded cursor-pointer"
      >
        Send
      </button>
    </div>
  </div>
</div>
