<footer class="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h4>SHOP</h4>
                    <ul>
                        <li><a href="#">Trending</a></li>
                        <li><a href="#">Brands</a></li>
                        <li><a href="#">Categories</a></li>
                        <li><a href="#">Deals</a></li>
                        <li><a href="#">How it works</a></li>
                        <li><a href="#">Gift card exchange</a></li>
                        <li><a href="#">Create an account</a></li>
                    </ul>
                </div>
                <div class="col">
                    <h4>SELL</h4>
                    <ul>
                        <li><a href="#">How to sell</a></li>
                        <li><a href="#">Packaging</a></li>
                        <li><a href="#">Shipping</a></li>
                        <li><a href="#">Getting paid</a></li>
                        <li><a href="#">Authenticate</a></li>
                    </ul>
                </div>
                <div class="col">
                    <h4>SUPPORT</h4>
                    <ul>
                        <li><a href="#">Contact Us</a></li>
                        <li><a href="#">Help Center</a></li>
                        <li><a href="#">Service Status</a></li>
                        <li><a href="#">Marketplace Guidelines</a></li>
                        <li><a href="#">Safety Guidelines</a></li>
                        <li><a href="#">Buyer Protection</a></li>
                        <li><a href="#">Seller Protection</a></li>
                    </ul>
                </div>
                <div class="col">
                    <h4>COMPANY & POLICIES</h4>
                    <ul>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Careers</a></li>
                        <li><a href="#">Policy Center</a></li>
                    </ul>
                </div>
                <div class="col">
                    <h4>WE ACCEPT</h4>
                    <!-- Add payment method images here -->
                </div>
                <div class="col">
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <p>&copy; 2024 SecondLife, Inc. All rights reserved.</p>
            <p><a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a> | <a href="#">Licenses / Disclosures</a></p>
        </div>
    </div>
</footer>
