<!-- src/app/upgrade-account/upgrade-account.component.html -->
<div class="container">
    <div class="offer-section">
        <div class="offer-text">
            <h1>Chỉ <span class="highlight">39,000đ</span> cho 7 ngày dùng thử gói PRO Đồ Điện Tử</h1>
            <p>Trải nghiệm gói bán hàng tiết kiệm, tiện lợi và hiệu quả cao, không giới hạn số tin đăng hiển thị trên
                Second Life</p>
            <ul>
                <li>4 lượt đăng tin</li>
                <li>2 lần đẩy tin</li>
                <li>Tiết kiệm <strong>31.000đ</strong> so với mua lẻ</li>
            </ul>
            <div class="buttons">
                <button mat-raised-button color="primary">Dùng thử 7 ngày</button>
                <button mat-button (click)="scrollToPackages()">Xem tất cả các gói</button>
            </div>
            <p>Chỉ áp dụng dành cho ngành hàng Đồ Điện Tử.</p>
            <p>Lượt đăng tin không được vượt qua giới hạn đăng tin của tài khoản.</p>
            <p>Ưu đãi duy nhất 1 lần cho mỗi tài khoản chưa từng sử dụng gói PRO.</p>
            <p>Lưu ý: Bạn chỉ được sử dụng một gói trong vòng 30 ngày.</p>
        </div>
        <div class="offer-image">
            <img src="assets/images/promo-image.png" alt="Promotion Image">
        </div>
    </div>
    <h2 id="packages">Chọn gói PRO Điện Tử theo nhu cầu</h2>
    <hr>
    <div class="package-container" *ngIf="packages.length">
        <div class="package" *ngFor="let package of packages">
            <h3>{{package.title}}</h3>
            <div class="package-details">
                <h4>{{package.subtitle}}</h4>
                <p>{{ package.description}}</p>
                <p class="price">
                    <span>{{package.originalPrice}}đ</span>
                    <strong> {{package.discountedPrice}}đ / 30 ngày</strong>
                </p>
                <p>Tiết kiệm {{package.discountPercentage}}% so với giá mua lẻ (*)</p>
                <button mat-raised-button color="primary" (click)="onBuyNow(package)">Mua ngay</button>
                <ul>
                    <li>Lượt đăng tin: {{package.postLimit}}</li>
                    <li>Không giới hạn số lượng tin đăng hiển thị trên Second Life</li>
                </ul>
            </div>
        </div>
    </div>
    <p class="disclaimer">(*) Số tiền tiết kiệm được khi mua gói PRO so với giá mua lẻ từng dịch vụ. Xem bảng so sánh
        giá</p>
</div>