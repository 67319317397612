import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product/product';
import { ProductService } from '../product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { PagedResult } from 'src/app/models/paged-result.model';
import slugify from 'slugify';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent implements OnInit {
  products: Product[] = [];
  filteredProducts: Product[] = [];
  sortOrder: string = '';
  totalProducts: number = 100;
  pageSize: number = 8;
  currentPage: number = 0;
  searchQuery: string = '';
  loading = true; // Loading state
  zeroPriceProducts: Product[] = [];
  constructor(
    private productService: ProductService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadProducts();
    this.getZeroPriceProducts();
  }

  sanitizeName(name: string): string {
    return slugify(name, { lower: true });
  }

  findFirstImageUrl(urls: string[]): string | undefined {
    if (urls) {
      return urls.find((url) =>
        /\.(jpeg|jpg|gif|png)$/i.test(url.toLowerCase())
      );
    }
    return undefined;
  }
  getZeroPriceProducts(): void {
    this.productService.getProductsByPriceRange(0, 0).subscribe((products) => {
      this.zeroPriceProducts = products;
    });
  }
  loadProducts(): void {
    this.productService
      .getProducts(this.currentPage + 1, this.pageSize)
      .subscribe((data: PagedResult<Product>) => {
        this.products = data.items;
        this.filteredProducts = data.items;
        this.loading = false;
        this.applyFilter();
      });
  }
  splitEmail(email: string): string {
    if (email.includes('@')) return email.split('@')[0];
    return email;
  }
  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadProducts();
  }

  applyFilter(): void {
    const filterValue = this.searchQuery.toLowerCase();
    this.filteredProducts = this.products.filter(
      (product) =>
        product.title.toLowerCase().includes(filterValue) ||
        new RegExp(`^${filterValue}`).test(product.price.toString())
    );
    this.sortProduct(this.sortOrder);
  }

  onSearchChange(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.searchQuery = filterValue;
    this.applyFilter();
  }

  sortProduct(event: Event | string): void {
    const sortValue =
      typeof event === 'string'
        ? event
        : (event.target as HTMLSelectElement).value;
    this.sortOrder = sortValue; // Save the current sort order
    switch (sortValue) {
      case 'priceLowHigh':
        this.filteredProducts.sort((a, b) => a.price - b.price);
        break;
      case 'priceHighLow':
        this.filteredProducts.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }
  }
}
