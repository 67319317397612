import { Component } from '@angular/core';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent {
  notifications: any[] = [];
  conversationId: string = '';
  sender: string = '';
  currentUser: string = '';
  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(
      localStorage.getItem('current_user')!
    ).userName;
    this.notificationService.notifications$.subscribe((notifications) => {
      this.notifications = notifications;
      this.conversationId =
        notifications.length > 0
          ? notifications[notifications.length - 1].conversationId
          : '';
      this.sender =
        notifications.length > 0
          ? notifications[notifications.length - 1].sender
          : '';
    });
  }
  markAsRead(notification: any): void {
    notification.read = true;
    this.router.navigate(['/chat'], {
      queryParams: { conversationId: this.conversationId },
    });
  }
}
