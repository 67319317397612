import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChatComponent } from './chat.component';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VideoChatComponent } from './video-chat/video-chat.component';
import { AppComponent } from 'src/app/app.component';
@NgModule({
  declarations: [ChatComponent, VideoChatComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    EmojiModule,
    MatProgressSpinnerModule,
    PickerModule,
    MatInputModule,
    SharedModule,
  ],
  providers: [VideoChatComponent, AppComponent],
  exports: [VideoChatComponent],
})
export class ChatModule {}
