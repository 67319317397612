import { Component, HostListener, ElementRef, ViewChild } from '@angular/core';
import { CartService } from './cart.service';
import { OnInit } from '@angular/core';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent {
  constructor(public cartService: CartService) {}
  cartItems: any[] = [];
  closeCart() {
    this.cartService.hideCart();
  }
  ngOnInit(): void {
    this.cartService.cartItems.subscribe((items) => {
      this.cartItems = items;
    });
  }
  removeItem(item: any) {
    this.cartService.removeFromCart(item);
  }
}
