import { Component, OnInit } from '@angular/core';
import { AccountService } from '../user-profile/account.service';
import { UserProfile } from 'src/app/models/user.profile';
import { PostManagementService } from './post-management.service';
import { DBkeys } from 'src/app/services/db-keys';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-post-management',
  templateUrl: './post-management.component.html',
  styleUrls: ['./post-management.component.css'],
})
export class PostManagementComponent implements OnInit {
  userName = 'Hùng Nguyễn';
  token: number = 0;
  selectedIndex = 0;
  userProfile: UserProfile = new UserProfile();
  displayedPosts: any[] = [];
  expiredPosts: any[] = [];
  rejectedPosts: any[] = [];
  paymentPendingPosts: any[] = [];
  draftPosts: any[] = [];
  pendingPosts: any[] = [];
  hiddenPosts: any[] = [];
  loading = true; // Loading state
  constructor(
    private accountService: AccountService,
    private postService: PostManagementService
  ) {
    this.accountService.getUserProfile().subscribe((data) => {
      this.userProfile = data;
      this.userName = data.userName;
      this.token = data.token!;
    });
    this.userName = JSON.parse(
      localStorage.getItem(DBkeys.CURRENT_USER)!
    )?.userName;
  }

  ngOnInit(): void {
    this.postService
      .getProductsByUserName(this.userName)
      .subscribe((response) => {
        this.categorizePosts(response.items);
        this.loading = false;
      });
  }
  categorizePosts(posts: any[]): void {
    posts.forEach((post) => {
      switch (post.state) {
        case 'Accept':
          this.displayedPosts.push(post);
          break;
        case 'Reject':
          this.rejectedPosts.push(post);
          break;
        case 'Expired':
          this.expiredPosts.push(post);
          break;
        case 'PaymentPending':
          this.paymentPendingPosts.push(post);
          break;
        case 'Draft':
          this.draftPosts.push(post);
          break;
        case 'Pending':
          this.pendingPosts.push(post);
          break;
        case 'Hidden':
          this.hiddenPosts.push(post);
          break;
        default:
          break;
      }
    });
  }
  createStore(): void {
    // Logic để tạo cửa hàng
  }
  deleteProduct(id: string) {
    Swal.fire({
      title: 'Bạn có chắc chắn muốn xóa?',
      text: 'Bạn sẽ không thể khôi phục lại bài đăng này!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Có, xóa nó!',
      cancelButtonText: 'Hủy',
    }).then((result) => {
      if (result.isConfirmed) {
        this.postService.deleteProduct(id).subscribe(
          (next) => {
            this.displayedPosts = this.displayedPosts.filter(
              (post) => post.id !== id
            );
            Swal.fire('Đã xóa!', 'Sản phẩm của bạn đã được xóa.', 'success');
          },
          (error) => {
            Swal.fire('Lỗi!', 'Có lỗi xảy ra khi xóa sản phẩm.', 'error');
          }
        );
      }
    });
  }
  addBalance(): void {
    // Logic để thêm số dư
  }

  createPost(): void {
    // Logic để tạo bài đăng mới
  }

  loadPosts(): void {
    // Gọi API để lấy danh sách tin đăng và gán vào các biến tương ứng
  }
}
